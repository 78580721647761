import styled from 'styled-components';
import { css } from '@styled-system/css';
import { variant } from 'styled-system';
import { composeStyleProps } from './shared';

const ButtonVariants = variant({
  variants: {
    primary: {
      color: 'white',
      textDecoration: 'none',
      bg: 'button.primary',
      border: 2,
      borderStyle: 'solid',
      borderColor: 'button.primary',
      ':hover': {
        bg: 'button.primaryHover',
        borderColor: 'button.primaryHover',
      },
    },
    outline: {
      color: 'black',
      textDecoration: 'none',
      bg: 'white',
      border: 2,
      borderStyle: 'solid',
      borderColor: 'black',
      ':hover': {
        borderColor: 'brand',
        color: 'brand',
      },
    },
    link: {
      px: 0,
      pb: 2,
      bg: 'transparent',
      borderRadius: 0,
      borderBottom: 3,
      borderStyle: 'solid',
      borderColor: 'brand',
      color: 'black',
    },
    accent: {
      color: 'white',
      textDecoration: 'none',
      bg: 'pallete.indigo.medium',
      ':hover': {
        bg: 'pallete.indigo.dark',
      },
    },
    secondary: {
      color: 'white',
      textDecoration: 'none',
      bg: 'button.secondary',
      ':hover': {
        bg: 'button.secondaryHover',
      },
    },
    icon: {
      color: 'pallete.blue.dark',
      bg: 'background.tint2',
      textDecoration: 'none',
      borderRadius: 128,
      px: 2,
      py: 2,
      ':hover': {
        bg: 'icon.muted',
      },
    },
  },
});

const Button = styled('button')`
  cursor: pointer;
  text-transform: ${(props) => props.textTransform};
  appearance: none;
  outline: 0;
  border: 0;
  ${composeStyleProps};
  ${ButtonVariants};
`;

Button.defaultProps = {
  display: 'flex',
  textAlign: 'center',
  fontWeight: 'semiBold',
  fontSize: [2, 3],
  lineHeight: '500',
  fontFamily: 'sans',
  flexDirection: 'row',
  alignItems: 'center',
  my: 2,
  px: [4, 8, 10],
  py: [2, null, 3],
  variant: 'primary',
  borderRadius: 128,
};

export default Button;
