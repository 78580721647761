import * as React from 'react';
import {
  PrismicPreviewProvider,
  withPrismicUnpublishedPreview,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews';
import PrismicBlogPost from '/pages/blog/{PrismicBlogPost.uid}';
import PrismicLandingPage from '/pages/{PrismicLandingPage.uid}';
import linkResolver from '/utils/linkResolver';

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
        linkResolver,
        componentResolver: componentResolverFromMap({
          blog_post: PrismicBlogPost,
          landing_page: PrismicLandingPage,
        }),
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
);

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`);
    console.log(`# IntersectionObserver is polyfilled!`);
  }
};

import '@fontsource/inter';
import '@fontsource/roboto-slab';
