import styled from 'styled-components';
import { variant } from 'styled-system';
import { composeStyleProps } from './shared';

const Label = styled('label')(variant({ key: 'variants.headings' }), composeStyleProps);

Label.defaultProps = {
  variant: '100',
};

export default Label;
