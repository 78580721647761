import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import htmlSerializer, { htmlSerializerFactory } from '/utils/htmlSerializer';

export const RichTextType = PropTypes.arrayOf(PropTypes.shape({ raw: PropTypes.object, richText: PropTypes.object }));

export const containerStyles = {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  justifyContent: ['flex-start', 'center'],
  minHeight: ['500px', null, 'calc(100vh - 132px)', null],
  pt: [0, 0, 0, 0],
  pb: [4, 4, 4, 4],
  px: [6, 10, 6, 12],
  bg: 'background.default',
};

export const contentFadeIn = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

export const sectionStyles = {
  position: 'relative',
  justifyContent: ['center', null, 'stretch'],
  mb: [6, null, 8, 10],
  width: '100%',
  gap: [0, null, 10, null],
  flexWrap: ['wrap', null, 'nowrap', null],
};

export const appImageStyles = {
  mx: 0,
  width: ['96vw', null, '100%', null],
  maxWidth: ['100vw', null, '60vw'],
  height: 'auto',
  borderRadius: 2,
  boxShadow: 'large',
};

export const PrismicRichText = ({ content, ...props }) => (
  <RichText render={content.richText} htmlSerializer={htmlSerializerFactory(props)} />
);

PrismicRichText.propTypes = {
  content: RichTextType,
};
