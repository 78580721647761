import colors from './colors';
import fonts from './fonts';
import headings from './variants/headings';
import text from './variants/text';
import blockquote from './variants/blockquote';

const { fontFamily, fontSize, lineHeight, sizes } = fonts;
const space = [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 48, 56, 64, 128, 256];

const breakpoints = ['720px', '900px', '1024px', '1200px', '1700px'];
breakpoints.sm = breakpoints[0]; // eslint-disable-line
breakpoints.md = breakpoints[1]; // eslint-disable-line
breakpoints.default = breakpoints[2]; // eslint-disable-line
breakpoints.lg = breakpoints[3]; // eslint-disable-line
breakpoints.xl = breakpoints[4]; // eslint-disable-line

export default {
  breakpoints,
  fontSizes: fontSize,
  sizes,
  fontWeights: [400, 600],
  lineHeights: lineHeight,
  colors,
  space,
  radii: [0, 4, 8, 16, 24],
  fonts: fontFamily,
  shadows: {
    boxTintSmall: `0 0 0 2px ${colors.background.brandTint}`,
    boxTintMedium: `0 0 0 8px ${colors.background.brandTint}`,
    boxTintLarge: `0 0 0 16px ${colors.background.brandTint}`,
    small: `
    0.5px 1px 1px rgba(var(--shadow-color, 0, 0, 0) , 0.2)
  `,
    medium: `
    1px 2px 2px rgba(var(--shadow-color, 0, 0, 0) , 0.12),
    2px 4px 4px rgba(var(--shadow-color, 0, 0, 0) , 0.12),
    3px 6px 6px rgba(var(--shadow-color, 0, 0, 0) , 0.12)
  `,
    large: `
    1px 2px 2px rgba(var(--shadow-color, 0, 0, 0) , 0.08),
    2px 4px 4px rgba(var(--shadow-color, 0, 0, 0) , 0.08),
    4px 8px 8px rgba(var(--shadow-color, 0, 0, 0) , 0.08),
    8px 16px 16px rgba(var(--shadow-color, 0, 0, 0) , 0.08)
  `,
    hover: `
    1px 2px 2px rgba(var(--shadow-color, 0, 0, 0) , 0.08),
    2px 4px 4px rgba(var(--shadow-color, 0, 0, 0) , 0.08),
    4px 8px 8px rgba(var(--shadow-color, 0, 0, 0) , 0.08),
    8px 16px 16px rgba(var(--shadow-color, 0, 0, 0) , 0.08)
  `,
    xlarge: `
    1px 2px 2px rgba(var(--shadow-color, 0, 0, 0) , 0.08),
    2px 4px 4px rgba(var(--shadow-color, 0, 0, 0) , 0.08),
    4px 8px 8px rgba(var(--shadow-color, 0, 0, 0) , 0.08),
    8px 16px 16px rgba(var(--shadow-color, 0, 0, 0) , 0.08),
    16px 32px 32px rgba(var(--shadow-color, 0, 0, 0) , 0.08)
`,
  },
  maxWidths: ['64px', '128px', '256px', '512px', '640px', '960px', '1120px'],

  // These are our custom variants
  variants: {
    headings,
    text,
    blockquote,
  },
  textStyles: text,
};
