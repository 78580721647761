import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import htmlSerializer, { htmlSerializerFactory } from '/utils/htmlSerializer';
import { Text, Heading, Button, Flex, Container, Section } from '/components/system';
import { SignupButton, Accordion } from '/components';
import { RichTextType, sectionStyles } from './shared';

const FAQ = ({
  slice: {
    items,
    primary: { header, content },
  },
}) => {
  const accordionSections = items.map((it) => ({
    title: it.question.richText[0].text,
    content: it.answer.richText[0].text,
  }));
  return (
    <Container bg="background.default">
      <Section {...sectionStyles}>
        <Flex flexDirection="column" rowGap={10} width={'100%'}>
          <RichText render={header.richText} htmlSerializer={htmlSerializer} />
          <Accordion sections={accordionSections} />
        </Flex>
      </Section>
    </Container>
  );
};

export const FAQSliceType = PropTypes.shape({
  slice_type: PropTypes.oneOf(['faq_section']),
  primary: {
    header: RichTextType,
    content: RichTextType,
  },
  items: PropTypes.arrayOf(PropTypes.shape({ question: RichTextType, answer: RichTextType })),
});

FAQ.propTypes = {
  slice: FAQSliceType,
};

export default FAQ;
