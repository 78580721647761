import styled from 'styled-components';

import Box from './Box';
import { composeStyleProps } from './shared';

const Container = styled(Box)(composeStyleProps);

Container.defaultProps = {
  justifyContent: 'center',
  px: [6, 10, 12, 12],
};

export default Container;
