import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';
import logo from '../assets/images/logo.svg';
import { Box, Flex, Link, Image, Button } from './system';
import SignupButton from './SignupButton';

export const Navbar = ({
  signupButtonText = 'Try Heyday',
  showCommunityLinks = false,
  showProductLinks = true,
  signupButtonLink,
}) => {
  const color = 'text.default';

  const linkStyles = {
    color,
    my: 3,
    mr: 3,
    fontSize: '400',
  };

  return (
    <Flex flexDirection={'row'} justifyContent="space-between" alignItems={['center', 'center', null, null]} flex="1">
      <Box>
        <Link as={GatsbyLink} to="/" px={0} my={3}>
          <Image src={logo} height={32} width={'auto'} />
        </Link>
      </Box>
      <Flex flexDirection="row" justifyContent={'flex-end'} alignItems="center" flex="0 1 280px" gap={2}>
        {showCommunityLinks && (
          <Link display={['none', null, 'inline-block', null]} as={GatsbyLink} to="/blog/" {...linkStyles}>
            Blog
          </Link>
        )}
        {showProductLinks && (
          <>
            <Link display={['none', null, 'inline-block', null]} as={GatsbyLink} to="/about/" {...linkStyles}>
              About
            </Link>
            <Link as={GatsbyLink} to="/pricing/" display={['none', null, 'inline-block', null]} {...linkStyles}>
              Pricing
            </Link>
            <Link as="a" href="/app/login" display={['none', null, 'inline-block', null]} {...linkStyles}>
              Login
            </Link>
          </>
        )}
        <SignupButton
          variant="outline"
          text={signupButtonText}
          href={signupButtonLink}
          lineHeight={'line'}
          fontSize="400"
          whiteSpace="nowrap"
          px={4}
          py={2}
        />
      </Flex>
    </Flex>
  );
};

Navbar.propTypes = {
  signupButtonText: PropTypes.string,
  signupButtonLink: PropTypes.string,
  showCommunityLinks: PropTypes.bool,
  showProductLinks: PropTypes.bool,
};
