import styled from 'styled-components';

import Flex from './Flex';

const Section = styled(Flex)({});

Section.defaultProps = {
  mx: 'auto',
  py: [4, null],
  maxWidth: '1200px',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  flex: '1 1 auto',
};

export default Section;
