// @flow
import * as React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { themeGet } from '@styled-system/theme-get';
import { system } from 'styled-system';
import parse from 'html-react-parser';

import { composeStyleProps } from './shared';
import { svgs } from '../../icon';
import Box from './Box';

const inlineStyles = {
  display: 'inline-block',
  verticalAlign: 'top',
};

const BaseIconSvg = ({ iconName, className }) => {
  if (!(iconName in svgs)) {
    throw new Error(`Invalid icon ${iconName}`);
  }
  const svg = svgs[iconName].replace(/fill="black"/g, '');

  const options = {
    replace: (domNode) => {
      if (domNode.name === 'svg') {
        // eslint-disable-next-line
        domNode.attribs = {
          className,
          ...domNode.attribs,
        };
      }
      return domNode;
    },
  };

  return parse(svg, options);
};

BaseIconSvg.propTypes = {
  iconName: PropTypes.string,
  className: PropTypes.string,
};

const IconSvg = styled(BaseIconSvg)(
  composeStyleProps,
  `
  fill: currentColor;
  transform-origin: 50% 50%;
  overflow: visible;
`
);

const inlineSvgProps = {
  height: '1em',
  verticalAlign: 'text-top',
};

const directions = {
  up: '0deg',
  right: '90deg',
  down: '180deg',
  left: '270deg',
};

const Icon = (props) => {
  const { name, size = '600', color = 'icon.default', inline = false } = props;

  let iconName = name.replace('-', '_');
  let transform = null;

  if (name.includes('point-')) {
    const direction = name.split('point-')[1];
    iconName = 'point';
    transform = `rotate(${directions[direction]})`;
  }

  return (
    <IconSvg
      iconName={iconName}
      transform={transform}
      color={color}
      verticalAlign={'top'}
      width={'auto'}
      {...(inline && inlineSvgProps)}
      {...props}
    />
  );
};

Icon.propTypes = {
  name: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  inline: PropTypes.boolean,
};

export default Icon;
