import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { RichText } from 'prismic-reactjs';

import htmlSerializer, { htmlSerializerFactory } from '/utils/htmlSerializer';
import { Flex, Section, GatsbyImage, Container, Blockquote, Text, VisibleVideo, Icon, Box } from '/components/system';
import { Testimonials } from '/components/Home/Testimonials';
import LandingPageSection from './LandingPageSection';

const ExistingSection = ({
  slice: {
    primary: { preprogrammed_section, linked_section },
    slice_type,
  },
  index,
}) => {
  if (preprogrammed_section === 'Testimonials') {
    return <Testimonials />;
  } else if (linked_section.document) {
    return <LandingPageSection section={linked_section.document} />;
  }
  return null;
};

export const ExistingSectionSliceType = PropTypes.shape({
  slice_type: PropTypes.oneOf(['existing_section']),
  primary: PropTypes.shape({
    preprogrammed_section: PropTypes.string,
    linked_section: PropTypes.object,
  }),
});

ExistingSection.propTypes = {
  slice: ExistingSectionSliceType,
  index: PropTypes.number,
};

export default ExistingSection;
