import { useCallback, useEffect, useState, useRef } from 'react';
import useIsClient from '/hooks/useIsClient';

export const onGrowsurfReady = (func) => {
  if (window.growsurf) {
    func();
  } else {
    window.addEventListener('grsfReady', func);
  }
};

const addGrsfScript = (grsfCampaignId) => {
  // GrowSurf Universal Code
  const growsurfScriptElem = window.document.querySelector(`script[grsf-campaign]`);
  if (growsurfScriptElem) {
    // Only do anything if the campaign ID is different
    // Check to see if GrowSurf is available
    onGrowsurfReady(() => {
      if (growsurfScriptElem.getAttribute('grsf-campaign') !== grsfCampaignId) {
        console.log('Changing growsurf campaign id', growsurfScriptElem.getAttribute('grsf-campaign'), grsfCampaignId);
        growsurfScriptElem.setAttribute('grsf-campaign', grsfCampaignId);
        window.growsurf.init({ campaignId: grsfCampaignId });
      }
    });
  } else {
    const script = document.createElement('script');
    script.src = 'https://app.growsurf.com/growsurf.js?v=2.0.0';
    script.setAttribute('grsf-campaign', grsfCampaignId);
    script.async = true;
    document.head.appendChild(script);
  }
};

const useGrowsurf = (grsfCampaignId) => {
  const growsurf = useRef(null);
  const addScript = useCallback(() => {
    addGrsfScript(grsfCampaignId);
    onGrowsurfReady(() => {
      growsurf.current = window.growsurf;
    });
  }, [grsfCampaignId]);

  useEffect(() => {
    // Check if the page has already loaded
    if (window.document.readyState === 'complete') {
      addScript();
    } else {
      window.addEventListener('load', addScript);
      // Remove the event listener
      return () => {
        window.removeEventListener('load', addScript);
      };
    }
    return () => {};
  }, [addScript]);

  return new Proxy(growsurf, {
    get: (target, prop) => {
      if (target.current && prop in target.current) {
        return target.current[prop];
      } else {
        return null;
      }
    },
  });
};

export const useGrowsurfReferrer = () => {
  const [referrer, setReferrer] = useState(null);
  const isClient = useIsClient();

  useEffect(() => {
    if (isClient) {
      onGrowsurfReady(() => {
        const referrerId = window.growsurf.getReferrerId();
        if (referrerId) {
          window.growsurf.getParticipantById(referrerId).then((participant) => {
            if (participant) {
              setReferrer(participant);
            }
          });
        }
      });
    }
  }, [isClient]);

  useEffect(() => {
    if (isClient && referrer) {
      window.analytics.identify({
        referrer: {
          id: referrer.id,
          firstName: referrer.firstName,
          lastName: referrer.lastName,
        },
      });
    }
  }, [isClient, referrer]);

  return referrer;
};

export default useGrowsurf;
