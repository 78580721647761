// @flow
import { rgba, darken, lighten } from 'polished';

import spectrum from './spectrum';
import pallete from './pallete';

const brand = '#028030';
const negative = '#E34616';
const secondary1 = '#F55F5D';
const secondary2 = '#7E49BF';
const yellow = '#FEBF00';
const blue = '#3C5CDF';
const peach = '#FFB799';

export default {
  pallete, // for use on objects and for extended background options
  brand,
  negative,
  secondary1,
  secondary2,
  blue,
  peach,
  yellow,
  red: negative,
  purple: secondary2,

  background: {
    default: spectrum.neutral[1],
    light: spectrum.white,
    dark: spectrum.black,
    tint1: spectrum.neutral[0],
    tint2: pallete.neutral.light,
    overlay: rgba(spectrum.neutral[7], 0.7),
    brandTint: rgba(brand, 0.5),
    secondary1: lighten(0.1, secondary1),
    secondary2: darken(0.1, secondary2),
    secondary1tint: rgba(secondary1, 0.5),
    secondary2tint: rgba(secondary2, 0.2),
    yellow: lighten(0.1, yellow),
    blue: darken(0.1, blue),
    peach: lighten(0.1, peach),
  },

  border: {
    muted: spectrum.neutral[0],
    default: spectrum.neutral[1],
    dark: spectrum.neutral[4],

    primary: pallete.blue.base,
    secondary: pallete.teal.base,
  },

  button: {
    primary: brand,
    primaryHover: darken(0.1, brand),
    secondary: spectrum.black,
    secondaryHover: lighten(0.2, spectrum.black),
    ghost: spectrum.white,
    overlayLight: 'rgba(255,255,255,.15)',
    overlayLightHover: 'rgba(255,255,255,.25)',
    base: pallete.blue.darkest,
    muted: spectrum.neutral[2],
    disabled: spectrum.neutral[1],
    success: pallete.green.base,
    info: pallete.cyan.base,
    danger: pallete.red.base,
    warning: pallete.yellow.base,
  },

  text: {
    muted: spectrum.neutral[6],
    default: spectrum.black,
    dark: spectrum.black,
    light: spectrum.white,
    selected: pallete.blue.base,
    overlayLight: 'rgba(255,255,255,.7)',
    slightlyMuted: pallete.neutral.dark,

    primary: pallete.blue.base,
    secondary: pallete.teal.base,

    success: pallete.green.base,
    info: pallete.blue.light,
    danger: pallete.red.base,
    warning: pallete.yellow.base,
  },

  icon: {
    muted: spectrum.neutral[4],
    default: spectrum.neutral[6],
    selected: pallete.blue.base,
    overlayLight: 'rgba(255,255,255,.7)',

    success: pallete.green.base,
    info: pallete.cyan.base,
    danger: pallete.red.base,
    warning: pallete.yellow.base,
  },
};
