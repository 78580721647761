import PropTypes from 'prop-types';
import React from 'react';
import { Container, Box } from './system';

const Footer = ({ children, ...props }) => (
  <Container as="footer" pb={4} borderRadius={0} mx={0} pt={0} px={[10, 10, 12]} {...props}>
    <Box mx={4} fontSize="300">
      {children}
    </Box>
  </Container>
);

Footer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Footer;
