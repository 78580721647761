import {
  space,
  color,
  layout,
  grid,
  typography,
  flexbox,
  background,
  border,
  position,
  shadow,
  compose,
  system,
} from 'styled-system';

const customProps = system({
  whiteSpace: true,
  transform: true,
  transition: true,
  objectFit: true,
  objectPosition: true,
  textDecoration: true,
  textTransform: true,
  isolation: true,
  textDecorationColor: {
    property: 'textDecorationColor',
    scale: 'colors',
  },
  gap: {
    property: 'gap',
    scale: 'space',
  },
  rowGap: {
    property: 'rowGap',
    scale: 'space',
  },
  columnGap: {
    property: 'columnGap',
    scale: 'space',
  },
});

export const composeStyleProps = compose(
  space,
  color,
  layout,
  grid,
  typography,
  flexbox,
  background,
  border,
  position,
  shadow,
  customProps
);

export const themed = (key) => (props) => props.theme[key];

export const baseStyleProps = [
  'space',
  'width',
  'borderRadius',
  'border',
  'borderTop',
  'borderBottom',
  'borderRight',
  'borderLeft',
  'borderColor',
  'fontSize',
  'color',
  'flex',
  'order',
  'alignSelf',
  'borders',
  'minHeight',
  'textAlign',
  'borderColor',
  'backgroundImage',
  'backgroundSize',
  'backgroundPosition',
  'backgroundRepeat',
  'opacity',
  'boxShadow',
  'flexDirection',
  'justifyContent',
  'alignItems',
  'display',
  'height',
  'maxHeight',
  'width',
  'maxWidth',
  'background',
  'zIndex',
  'left',
  'right',
  'top',
  'bottom',
  'position',
  'maxHeight',
  'minHeight',
];

export const textProps = ['lineHeight', 'fontWeight'];
