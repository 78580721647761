export default {
  white: '#fff',
  black: '#000',
  dark: {
    0: '#292424',
    1: '#201d1d',
    2: '#1b1818',
  },
  neutral: {
    0: '#F8F7F7',
    1: '#F2F0F0',
    2: '#e3e0e0',
    3: '#E0E0E0',
    4: '#c6c3c3',
    5: '#b2b2b2',
    6: '#a19f9f',
    7: '#8c8c8c',
    8: '#6c6c6c',
    9: '#4E4E4E',
  },
  red: {
    0: '#faedeb',
    1: '#f5dad6',
    2: '#f0c5be',
    3: '#eaaca3',
    4: '#e38f81',
    5: '#d96755',
    6: '#c35c4c',
    7: '#ab5143',
    8: '#8d4337',
    9: '#642f27',
  },
  pink: {
    0: '#faecf4',
    1: '#f5d8e8',
    2: '#efc2db',
    3: '#e9a7cc',
    4: '#e187ba',
    5: '#d65ca1',
    6: '#c15391',
    7: '#a9487f',
    8: '#8b3c69',
    9: '#632a4a',
  },
  fuschia: {
    0: '#f9ecfa',
    1: '#f3d8f5',
    2: '#ecc2ef',
    3: '#e4a7e9',
    4: '#db87e0',
    5: '#ce5cd6',
    6: '#ba53c1',
    7: '#a248a9',
    8: '#863c8b',
    9: '#602a63',
  },
  violet: {
    0: '#f2ecfa',
    1: '#e5d8f5',
    2: '#d6c2ef',
    3: '#c4a8e9',
    4: '#ae87e1',
    5: '#915cd6',
    6: '#8252c0',
    7: '#7248a8',
    8: '#5d3b8a',
    9: '#422a61',
  },
  grape: {
    0: '#ededfa',
    1: '#d9dbf5',
    2: '#c2c5ef',
    3: '#a8ace9',
    4: '#888ee1',
    5: '#5c64d6',
    6: '#525ac0',
    7: '#484ea8',
    8: '#3b408a',
    9: '#292d60',
  },
  indigo: {
    0: '#ebeff9',
    1: '#d4ddf2',
    2: '#bcc9eb',
    3: '#9fb2e3',
    4: '#7c96d8',
    5: '#4d71cb',
    6: '#4565b6',
    7: '#3c589f',
    8: '#314983',
    9: '#23335c',
  },
  turquoise: {
    0: '#e6f4f8',
    1: '#cce9f2',
    2: '#aedcea',
    3: '#8ccee2',
    4: '#64bdd8',
    5: '#33a8cc',
    6: '#2e97b8',
    7: '#2885a1',
    8: '#216e85',
    9: '#174e5f',
  },
  blue: {
    0: '#e8f2fb',
    1: '#d0e5f6',
    2: '#b5d6f1',
    3: '#96c5ec',
    4: '#72b1e6',
    5: '#4599de',
    6: '#3e8ac8',
    7: '#3679af',
    8: '#2d6491',
    9: '#204767',
  },
  cyan: {
    0: '#eaf8f7',
    1: '#d5f1ee',
    2: '#bde9e5',
    3: '#a3e0db',
    4: '#86d6d0',
    5: '#66ccc4',
    6: '#5cb8b1',
    7: '#51a29b',
    8: '#438681',
    9: '#30615d',
  },
  teal: {
    0: '#EDF8F2',
    1: '#def2e8',
    2: '#c5e9d7',
    3: '#a9dfc4',
    4: '#8ad3af',
    5: '#6ac597',
    6: '#5eaf87',
    7: '#519774',
    8: '#40775b',
    9: '#254636',
  },
  green: {
    0: '#f5fbf4',
    1: '#e1f2de',
    2: '#cbe9c5',
    3: '#b2dfaa',
    4: '#97d38b',
    5: '#79c56a',
    6: '#6cb05f',
    7: '#5d9751',
    8: '#497740',
    9: '#2b4626',
  },
  lime: {
    0: '#f2f9e8',
    1: '#e4f3d0',
    2: '#d5ecb7',
    3: '#c5e69b',
    4: '#b4de7d',
    5: '#a1d65c',
    6: '#91c153',
    7: '#7faa49',
    8: '#6a8d3c',
    9: '#4c662b',
  },
  yellow: {
    0: '#faf6ea',
    1: '#f6edd4',
    2: '#f1e3bd',
    3: '#ebd8a4',
    4: '#e6cd89',
    5: '#e0c16c',
    6: '#caae61',
    7: '#b29955',
    8: '#937f47',
    9: '#6a5c33',
  },
  orange: {
    0: '#f9f1ea',
    1: '#f4e2d4',
    2: '#eed1bb',
    3: '#e7bfa0',
    4: '#dfaa81',
    5: '#d6915c',
    6: '#c18353',
    7: '#a97248',
    8: '#8c5f3c',
    9: '#64442b',
  },
};
