import { Link as GatsbyLink } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { MDXProvider } from '@mdx-js/tag';
import { Box, Flex, Container, Section, Link, Image, Heading, Text, Ul, Ol, Highlight, Blockquote } from './system';
import Head from './Head';
import Header from './Header';
import theme from '../styles/theme';
import logo from '../assets/images/logo.svg';

const MDH1 = (props) => <Heading is="h1" variant="800" {...props} />;
const MDH2 = (props) => <Heading is="h2" variant="700" />;
const MDH3 = (props) => <Heading is="h3" variant="500" {...props} />;
const MDH4 = (props) => <Heading is="h4" variant="400" {...props} />;
const MDP = (props) => <Text {...props} />;
const MDA = (props) => <Link as="a" color="blue" {...props} />;
const MDUL = (props) => (
  <Text>
    <Ul {...props} />
  </Text>
);
const MDOL = (props) => (
  <Text>
    <Ol {...props} />
  </Text>
);

const TermsLayout = ({ children, title }) => (
  <ThemeProvider theme={theme}>
    <React.Fragment>
      <Head title={title} />
      <Container minHeight={640} position="relative" display="flex" mb={5}>
        <Section width={1} py={6} justifyContent="flex-start" alignItems="center" flexDirection="column">
          <Flex width={[1, 1, 0.8, 0.7]} pb={8} px={4} justifyContent="left" alignItems="center">
            <Link as={GatsbyLink} to="/" px={0} my={3}>
              <Image src={logo} height={42} />
            </Link>
          </Flex>
          <Box width={[1, 1, 0.8, 0.7, null]} pb={[6]} minHeight="100vh">
            <MDXProvider
              components={{
                h1: MDH1,
                h2: MDH2,
                h3: MDH3,
                h4: MDH4,
                p: MDP,
                a: MDA,
                ul: MDUL,
                ol: MDOL,
              }}
            >
              <Container px={4} color="black">
                <Heading variant="primary" as="h1">
                  {title}
                </Heading>
                {children}
              </Container>
            </MDXProvider>
          </Box>
        </Section>
      </Container>
    </React.Fragment>
  </ThemeProvider>
);

TermsLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default TermsLayout;
