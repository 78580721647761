import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { isMobile } from 'react-device-detect';
import { createPopup } from '@typeform/embed';
import '@typeform/embed/build/css/popup.css';

import { Button, Link, Box } from './system';
import useIsClient from '/hooks/useIsClient';
import { useGrowsurfReferrer } from '/hooks/useGrowsurf';

const useAnonymousId = () => {
  const isClient = useIsClient();
  const [anonymousId, setAnonymousId] = useState(null);

  useEffect(() => {
    if (isClient) {
      if (window.analytics.user) {
        setAnonymousId(window.analytics.user().anonymousId());
      }
    }
  });

  return anonymousId;
};

const SignupButton = ({ variant = 'primary', text = 'Try Heyday', useTypeform = false, href, ...props }) => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const isClient = useIsClient();
  const { utm_source, utm_medium, utm_content, utm_campaign, utm_term, r, li_fat_id } = queryParams;

  const anonymous_id = useAnonymousId();
  const referrer = useGrowsurfReferrer();

  const { toggle } = createPopup('oeES9Aoi', {
    hidden: {
      is_mobile: isMobile ? 'true' : 'false',
      utm_source,
      utm_medium,
      utm_content,
      utm_campaign,
      utm_term,
      anonymous_id,
      referrer_id: referrer?.id,
    },
  });

  const fullQueryParams = {
    ...queryParams,
    ajs_aid: anonymous_id,
    ajs_prop_referrer_id: referrer?.id,
    ajs_prop_li_fat_id: li_fat_id,
  };

  const link = queryString.stringifyUrl({ url: href || 'https://app.heyday.xyz/signup', query: fullQueryParams });

  return useTypeform ? (
    <Box key={isClient ? 'client' : 'server'}>
      <Button my={[4, 6]} variant={variant} fontWeight="600" onClick={toggle} {...props}>
        {text}
      </Button>
    </Box>
  ) : (
    <Link key={isClient ? 'client' : 'server'} href={link.replace('https://#', '#')}>
      <Button my={[4, 6]} variant={variant} fontWeight="600" {...props}>
        {text}
      </Button>
    </Link>
  );
};

SignupButton.propTypes = {
  variant: PropTypes.string,
  text: PropTypes.string,
  useTypeform: PropTypes.bool,
  href: PropTypes.string,
};

export default SignupButton;
