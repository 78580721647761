import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@styled-system/css';
import { Text, Heading, Button, Flex } from '/components/system';
import SubscribeButton from '/components/Blog/subscribe';

const colorMap = {
  brand: ['brand', 'white'],
  red: ['negative', 'white'],
  purple: ['secondary2', 'white'],
  yellow: ['background.yellow', 'black'],
  blue: ['background.blue', 'white'],
  Product: ['background.blue', 'white'],
  Subscribe: ['secondary2', 'white'],
  Community: ['background.peach', 'black'],
};

const CTA = ({
  slice: {
    primary: { main_text, sub_text, cta_button_text, cta_button_href, cta_type, override_bg_color },
  },
}) => {
  const [bgColor, textColor] = colorMap[override_bg_color || cta_type || 'brand'];
  return (
    <Flex
      flexDirection="column"
      justifyContent="space-around"
      bg={bgColor}
      borderRadius={3}
      minHeight={'300px'}
      p={8}
      my={7}
    >
      <Heading variant={'subheading'} fontWeight={'400'} color={textColor}>
        {main_text}
      </Heading>
      {sub_text && (
        <Text variant="body" color={textColor}>
          {sub_text}
        </Text>
      )}
      <Flex>
        {cta_type === 'Subscribe' ? (
          <SubscribeButton border="none" />
        ) : (
          <Button
            as={'a'}
            href={cta_button_href.url}
            px={5}
            variant="outline"
            fontWeight="600"
            fontSize={[2, 3]}
            css={css({ ':hover': { borderColor: 'currentColor', color: 'brand' } })}
          >
            {cta_button_text}
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export const CTASliceType = PropTypes.shape({
  slice_type: PropTypes.oneOf(['cta']),
  primary: PropTypes.shape({
    main_text: PropTypes.string,
    sub_text: PropTypes.string,
    cta_button_text: PropTypes.string,
    cta_button_href: PropTypes.shape({
      url: PropTypes.string,
    }),
    cta_type: PropTypes.string,
    override_bg_color: PropTypes.string,
  }),
});

CTA.propTypes = {
  slice: CTASliceType,
};

export default CTA;
