import colors from '../colors';
import fonts from '../fonts';

const { fontFamily, fontSize, lineHeight } = fonts;

export default {
  hero: {
    fontSize: [7, 8],
    fontWeight: 'normal',
    lineHeight: 'header',
    letterSpacing: '-0.06em',
    fontFamily: fontFamily.sans,
    color: colors.text.dark,
    mt: 0,
    mb: '0.75em',
  },
  page: {
    fontSize: [6, 7],
    fontWeight: 'normal',
    lineHeight: 'header',
    letterSpacing: '-0.06em',
    fontFamily: fontFamily.sans,
    color: colors.text.dark,
    mt: 0,
    mb: '0.75em',
  },
  primary: {
    fontSize: [5, 6],
    fontWeight: 'normal',
    lineHeight: 'header',
    letterSpacing: '-0.06em',
    fontFamily: fontFamily.sans,
    color: colors.text.dark,
    mt: 0,
    mb: '0.75em',
  },
  subheading: {
    fontSize: [4, 5],
    fontWeight: '600',
    lineHeight: 'subheader',
    letterSpacing: '-0.02em',
    fontFamily: fontFamily.sans,
    color: colors.text.dark,
    mt: 0,
    mb: '0.75em',
  },
  900: {
    fontSize: [6, 7],
    fontWeight: 'normal',
    lineHeight: 'header',
    letterSpacing: '-0.06em',
    fontFamily: fontFamily.sans,
    color: colors.text.dark,
    mt: '1.5em',
    mb: '0.75em',
  },
  800: {
    fontSize: [5, 6],
    fontWeight: 'normal',
    lineHeight: 'header',
    letterSpacing: '-0.06em',
    // letterSpacing: '-0.2px',
    fontFamily: fontFamily.sans,
    color: colors.text.dark,
    mt: '1.5em',
    mb: '0.75em',
  },
  700: {
    fontSize: [4, 5],
    fontWeight: '600',
    lineHeight: 'subheader',
    letterSpacing: '-0.02em',
    // letterSpacing: '-0.1px',
    fontFamily: fontFamily.sans,
    color: colors.text.dark,
    mt: '1.5em',
    mb: '0.75em',
  },
  600: {
    fontSize: fontSize['600'],
    fontWeight: '600',
    lineHeight: '100%',
    letterSpacing: '-0.02em',
    // letterSpacing: '-0.1px',
    // fontFamily: fontFamily.display,
    color: colors.text.dark,
    mt: '1.5em',
    mb: '0.75em',
  },
  500: {
    fontSize: fontSize['500'],
    fontWeight: 'normal',
    lineHeight: '95%',
    letterSpacing: '-0.02em',
    // letterSpacing: '-0.07px',
    // fontFamily: fontFamily.ui,
    color: colors.text.dark,
    mt: '1.5em',
    mb: '0.75em',
  },
  400: {
    fontSize: fontSize['400'],
    fontWeight: '600',
    lineHeight: '100%',
    // letterSpacing: '-0.2px',
    // fontFamily: fontFamily.ui,
    color: colors.text.dark,
    mt: '1.5em',
    mb: '0.75em',
  },
  300: {
    fontSize: fontSize['300'],
    fontWeight: 600,
    lineHeight: lineHeight['300'],
    letterSpacing: '0',
    // fontFamily: fontFamily.ui,
    color: colors.text.dark,
    mt: '1.5em',
    mb: '0.75em',
  },
  200: {
    fontSize: fontSize['200'],
    fontWeight: '600',
    lineHeight: lineHeight['200'],
    letterSpacing: '0',
    // fontFamily: fontFamily.ui,
    color: colors.text.muted,
    mt: '1.5em',
    mb: '0.75em',
  },
  100: {
    fontSize: fontSize['200'],
    fontWeight: '600',
    textTransform: 'uppercase',
    lineHeight: lineHeight['300'],
    letterSpacing: '0',
    // fontFamily: fontFamily.ui,
    color: colors.text.default,
    mt: '1.5em',
    mb: '0.75em',
  },
};
