import colors from '../colors';
import fonts from '../fonts';

const { fontFamily, fontSize, lineHeight } = fonts;

export default {
  body: {
    fontWeight: 400,
    lineHeight: 'body',
    color: colors.text.dark,
    fontSize: [2, 3],
  },
  longBody: {
    fontWeight: 400,
    lineHeight: 'longBody',
    color: colors.text.dark,
    fontSize: [2, 3],
  },
  subtext: {
    fontWeight: 400,
    lineHeight: 'body',
    color: colors.text.dark,
    fontSize: [0, 2],
  },
  label: {
    fontSize: [1, 2],
    fontWeight: 600,
  },
  sublabel: {
    fontWeight: 400,
    lineHeight: 'body',
    color: colors.text.dark,
    fontSize: [0, 2],
  },
  quote: {
    color: 'pallete.neutral.dark',
    fontWeight: 400,
    lineHeight: 'longBody',
    fontSize: [3, 4],
  },
  600: {
    fontSize: fontSize['600'],
    fontWeight: 400,
    lineHeight: 1.22,
    // letterSpacing: '-0.1px',
    // fontFamily: fontFamily.display,
    color: colors.text.dark,
  },
  500: {
    fontSize: fontSize['500'],
    fontWeight: 400,
    lineHeight: 1.22,
    // letterSpacing: '-0.07px',
    // fontFamily: fontFamily.ui,
    color: colors.text.dark,
  },
  400: {
    fontSize: fontSize['400'],
    fontWeight: 400,
    lineHeight: 1.22,
    // letterSpacing: '-0.2px',
    // fontFamily: fontFamily.ui,
    color: colors.text.dark,
  },
  300: {
    fontSize: fontSize['300'],
    fontWeight: 400,
    lineHeight: '122%',
    // letterSpacing: '0',
    // fontFamily: fontFamily.ui,
    color: colors.text.dark,
  },
  200: {
    fontSize: fontSize['200'],
    fontWeight: 400,
    lineHeight: '122%',
    // letterSpacing: '0',
    // fontFamily: fontFamily.ui,
    color: colors.text.muted,
  },
  100: {
    fontSize: fontSize['200'],
    fontWeight: 400,
    textTransform: 'uppercase',
    lineHeight: '122%',
    // letterSpacing: '0',
    // fontFamily: fontFamily.ui,
    color: colors.text.default,
  },
};
