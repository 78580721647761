import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import { getImage, StaticImage } from 'gatsby-plugin-image';
import Zoom from 'react-medium-image-zoom';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import 'react-medium-image-zoom/dist/styles.css';
import { sectionStyles, appImageStyles, RichTextType, PrismicRichText } from './shared';
import { TestimonialCard, TestimonialType } from '/components/Home/Testimonials';

import FollowedTopicsVideoMp4 from '../../assets/videos/FollowedTopics.mp4';
import FollowedTopicsVideoWebm from '../../assets/videos/FollowedTopics.webm';

import htmlSerializer, { htmlSerializerFactory } from '/utils/htmlSerializer';
import {
  Container,
  Section,
  Flex,
  Box,
  Heading,
  Text,
  SVG,
  GatsbyImage,
  Video,
  Icon,
  Button,
  VisibleVideo,
  Card,
} from '/components/system';

const query = graphql`
  query {
    googleSearchCompanion: file(relativePath: { eq: "product/googleSearchCompanion.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1200, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    contentCompanion: file(relativePath: { eq: "product/ContentCompanion.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1200, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    allVideos: allFile(filter: { extension: { in: ["mp4", "webm"] } }) {
      nodes {
        name
        extension
        publicURL
      }
    }
  }
`;

const InlineIcon = styled(Icon)`
  display: inline-block;
  vertical-align: text-center;
`;

const SelectableFlex = styled(Flex)`
  cursor: pointer;
`;

const handColors = ['yellow', 'peach', 'pallete.turquoise.base'];

const ScrollingBox = styled(Box)`
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Features = ({
  slice: {
    primary: { header },
    items,
  },
  index,
}) => {
  const data = useStaticQuery(query);
  const {
    allVideos: { nodes: videos },
  } = data;

  const { ref, inView } = useInView({
    threshold: 0.5,
    trackVisibility: true,
    delay: 100,
  });

  const [currentTab, setCurrentTab] = React.useState(0);
  const [autotransition, setAutotransition] = React.useState(true);

  const nextTab = React.useCallback(() => setCurrentTab((currentTab + 1) % 3), [currentTab, setCurrentTab]);
  const selectTab = React.useCallback(
    (tab) => {
      setAutotransition(false);
      setCurrentTab(tab);
    },
    [setAutotransition, setCurrentTab]
  );

  React.useEffect(() => {
    const timeout =
      autotransition &&
      inView &&
      setTimeout(() => {
        nextTab();
      }, 15000);

    return () => {
      if (timeout) {
        clearInterval(timeout);
      }
    };
  }, [nextTab, autotransition, inView]);

  const matchingVideos = videos.filter((v) => items[currentTab].video && v.name === items[currentTab].video);

  return (
    <Container overflow={'hidden'} bg="background.default">
      <Section {...sectionStyles} ref={ref}>
        <Flex flexDirection="column" maxWidth={'100%'}>
          <Flex my={6}>
            <PrismicRichText content={header} mb={0} variant="primary" />
          </Flex>
          <ScrollingBox mb={[4, null, 0, null]}>
            {items.map((fd, idx) => (
              <Button
                onClick={() => selectTab(idx)}
                key={fd.tab_text}
                variant={idx === currentTab ? 'primary' : 'outline'}
                fontSize={[1, 2]}
                lineHeight={0.8}
                fontWeight="600"
                height={40}
                flex="0 0 auto"
                display={'inline-block'}
                mr={[2, 4]}
              >
                {fd.tab_text}
              </Button>
            ))}
          </ScrollingBox>
          <Flex flexDirection={['column', null, 'row']} mt={[0, null, 8, 10]} gap={[0, null, 10]}>
            <Flex
              width={[1, 0.8, 0.5, 0.4]}
              mb={[6, 6, 0, 0]}
              flexDirection="column"
              justifyContent="flex-start"
              textAlign={['left', null, null]}
            >
              <PrismicRichText content={items[currentTab].header} mb={[4, 4, 8, 8]} variant="subheading" />
              <PrismicRichText content={items[currentTab].content} mb={4} variant="body" />
              {items[currentTab].testimonial?.document && (
                <TestimonialCard
                  rotated
                  mr={[0, 8]}
                  my={6}
                  testimonial={items[currentTab].testimonial.document.data}
                  handColor={handColors[currentTab % handColors.length]}
                  rotation={currentTab % 2 ? '5deg' : '-5deg'}
                />
              )}
            </Flex>
            <Flex
              width={[1, null, 0.5, 0.6]}
              pb={[0, 0, 0, 6]}
              minHeight={['unset', '564px', null, null]}
              flexDirection={'column'}
              position={'relative'}
              alignItems={['center', null, 'flex-start']}
              justifyContent="center"
            >
              <Zoom>
                {matchingVideos.length > 0 ? (
                  <VisibleVideo
                    muted
                    restartAfter={5000}
                    playsInline
                    preload="auto"
                    key={`feature:${header}:${currentTab}`}
                    id={items[currentTab].video}
                    {...appImageStyles}
                    boxShadow={items[currentTab].hide_shadow ? undefined : 'large'}
                  >
                    {matchingVideos.map((v) => (
                      <source key={v.extension} src={v.publicURL} type={`video/${v.extension}`} />
                    ))}
                  </VisibleVideo>
                ) : (
                  <GatsbyImage
                    key={`feature:${header}:${currentTab}`}
                    {...appImageStyles}
                    image={getImage(items[currentTab].image)}
                    boxShadow={items[currentTab].hide_shadow ? undefined : 'large'}
                  />
                )}
              </Zoom>
            </Flex>
          </Flex>
        </Flex>
      </Section>
    </Container>
  );
};

const FeatureType = PropTypes.shape({
  header: RichTextType,
  content: RichTextType,
  tab_text: PropTypes.string,
  image: PropTypes.object,
  video: PropTypes.string,
  hide_shadow: PropTypes.bool,
  testimonial: {
    document: {
      data: TestimonialType,
    },
  },
});

export const FeaturesSliceType = PropTypes.shape({
  slice_type: PropTypes.oneOf(['features']),
  primary: PropTypes.shape({
    header: RichTextType,
  }),
  items: PropTypes.arrayOf(FeatureType),
});

Features.propTypes = {
  slice: FeaturesSliceType,
  index: PropTypes.number,
};

export default Features;
