import styled from 'styled-components';
import Box from './Box';

const GridArea = styled(Box)({});

GridArea.defaultProps = {
  gridGap: 4,
  display: 'grid',
};

export default GridArea;
