import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React from 'react';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { RichText } from 'prismic-reactjs';
import css from '@styled-system/css';
import {
  TwitterShareButton,
  TwitterIcon,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
} from 'react-share';

import { Layout, Navbar, Header } from '/components';
import {
  Text,
  Card,
  Container,
  Section,
  Flex,
  Label,
  Box,
  Link,
  LinkButton,
  Button,
  Heading,
  GatsbyImage,
  Image,
  GridArea,
  Highlight,
  Blockquote,
  Icon,
  Video,
  Ul,
} from '/components/system';
import SliceZone from '/components/prismic/SliceZone';
import { PrismicRichText, RichTextType } from '/components/prismic/shared';
import Frame from '/assets/images/Frame.png';

export const query = graphql`
  query PrismicBlogPostQuery($id: String) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    prismicBlogPost(id: { eq: $id }) {
      _previewable
      data {
        author {
          document {
            ... on PrismicAuthor {
              id
              data {
                bio {
                  richText
                }
                linkedin_profile
                name
                twitter_profile
                profile_image {
                  gatsbyImageData
                }
              }
            }
          }
        }
        body {
          ... on PrismicBlogPostDataBodyGenericContent {
            id
            slice_type
            primary {
              content {
                raw
              }
            }
          }
          ... on PrismicBlogPostDataBodyLinkedList {
            id
            items {
              description
              link_title
            }
            slice_type
            primary {
              list_title {
                raw
              }
            }
          }
          ... on PrismicBlogPostDataBodyCta {
            id
            slice_type
            primary {
              override_bg_color
              cta_button_href {
                url
              }
              cta_button_text
              cta_type
              main_text
              sub_text
            }
          }
        }
        description
        title {
          text
        }
      }
      first_publication_date
      last_publication_date
      socialCard {
        publicURL
        childImageSharp {
          gatsbyImageData(width: 720, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  }
`;

const FrameBox = styled(Box)`
  background-image: url(${Frame});
  background-size: contain;
  overflow: hidden;
  height: 0;
  position: relative;
`;

const InnerBox = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 16%;
  padding-right: 16%;
`;

const StyledGatsbyImage = styled(GatsbyImage)``;

const FramedImage = (props) => (
  <FrameBox width={['100vw', 1]} mx={['calc(50% - 50vw)', 0]} pt={['52.25vw', '52.25%']} mb={5}>
    <InnerBox>
      <Image {...props} boxShadow="large" borderRadius={2} />
    </InnerBox>
  </FrameBox>
);

const AuthorDetails = ({ author }) => (
  <Card boxShadow={null} borderRadius={2}>
    <Flex my={5} mx={6} flexDirection={['row', 'column']} gridGap={4}>
      <Box borderRadius={'50%'} isolation="isolate" overflow="hidden" width="64px" height="64px" flex="0 0 64px">
        <GatsbyImage
          image={getImage(author.profile_image)}
          width={64}
          height={64}
          objectPosition="center center"
          objectFit="cover"
        />
      </Box>
      <Box>
        <Text as="h3" variant="500" fontWeight="500">
          {author.name}
        </Text>
        <PrismicRichText content={author.bio} fontSize={[1, 1]} />
        <Flex gridGap={2}>
          {author.twitter_profile && (
            <Link target="_blank" href={author.twitter_profile}>
              <Icon name="twitter_brand" size={24} />
            </Link>
          )}
          {author.linkedin_profile && (
            <Link target="_blank" href={author.linkedin_profile}>
              <Icon name="linkedin_brand" size={24} />
            </Link>
          )}
        </Flex>
      </Box>
    </Flex>
  </Card>
);

AuthorDetails.propTypes = {
  author: PropTypes.shape({
    name: PropTypes.string,
    bio: RichTextType,
    profile_image: PropTypes.object,
    twitter_profile: PropTypes.string,
    linkedin_profile: PropTypes.string,
  }),
};

const BlogPost = ({ location, data: { site, prismicBlogPost: document } }) => {
  const siteUrl = site && site.siteMetadata && site.siteMetadata.siteUrl;
  const canonicalUrl = siteUrl + location.pathname;
  const seoImage =
    document.socialCard && document.socialCard.publicURL
      ? siteUrl + document.socialCard.publicURL
      : 'https://assets.heyday.xyz/social/twitter.png';

  const author = document.data.author?.document?.data;

  const schema = {
    '@context': 'http://schema.org',
    '@type': 'NewsArticle',
    url: canonicalUrl,
    mainEntityOfPage: canonicalUrl,
    headline: document.data.title.text,
    description: document.data.description,
    image: [
      {
        '@type': 'ImageObject',
        url: seoImage,
      },
    ],
    datePublished: document.first_publication_date,
    dateModified: document.last_publication_date,
    isAccessibleForFree: true,
    publisher: {
      '@type': 'Organization',
      name: 'Heyday',
      logo: {
        '@type': 'ImageObject',
        url: 'https://assets.heyday.xyz/logo.png',
      },
      sameAs: ['https://twitter.com/heyday_hq'],
    },
    ...(author && { author: { '@type': 'Person', name: author.name } }),
  };

  return (
    <Layout
      showNav={true}
      seoTitle={document.data.title.text}
      seoImage={seoImage}
      seoDescription={
        document.data.description ||
        'Welcome to the Heyday blog! Read memory-boosting insights, published twice a month.'
      }
      schema={schema}
      navbarProps={{
        showCommunityLinks: true,
      }}
    >
      <Helmet>
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:type" content="article" />
        <meta property="article:published_time" content={document.first_publication_date} />
        <meta property="article:modified_time" content={document.last_publication_date} />
        <meta property="article:section" content="Blog" />
        {author && <meta property="article:author" content={author.name} />}
        {author && <meta property="author" content={author.name} />}
      </Helmet>
      <Section
        as="article"
        width={1}
        py={6}
        gridGap={[0, 8]}
        justifyContent="flex-start"
        alignItems="start"
        flexDirection={['column', null, 'row']}
        flexWrap="no-wrap"
      >
        <Flex
          maxWidth={'720px'}
          pb={[6]}
          flexDirection="column"
          justifyContent="center"
          width={['100%', null, 'auto']}
          flex={['0 0 auto', null, '0 1 720px']}
        >
          <Heading as="h1" variant="primary">
            {document.data.title.text}
          </Heading>
          <Flex mb={5} alignItems="center">
            <Text variant="500" mb={0} mt={0}>
              <time dateTime={document.first_publication_date}>
                {new Date(document.first_publication_date).toLocaleDateString('en-US', { dateStyle: 'long' })}
              </time>
            </Text>
            <Box ml={3}>
              <TwitterShareButton url={canonicalUrl} title={document.data.title.text} related={['heyday_hq']}>
                <TwitterIcon size={32} round />
              </TwitterShareButton>
            </Box>
            <Box ml={3}>
              <FacebookShareButton url={canonicalUrl} quote={document.data.title.text}>
                <FacebookIcon size={32} round />
              </FacebookShareButton>
            </Box>
            <Box ml={3}>
              <LinkedinShareButton
                url={canonicalUrl}
                title={document.data.title.text}
                summary={document.data.description}
                source="Heyday Blog"
              >
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
            </Box>
          </Flex>
          <StyledGatsbyImage
            image={getImage(document.socialCard)}
            boxShadow="large"
            borderRadius={2}
            display="block"
            mb={10}
            m={null}
          />
          <Box>
            <SliceZone slices={document.data.body} />
          </Box>
        </Flex>
        {author && (
          <Flex
            flexDirection="column"
            width={['100%', null, 'auto']}
            flex={['0 0 auto', null, '0 1 280px']}
            py={[0, null, 14]}
          >
            <AuthorDetails author={author} />
          </Flex>
        )}
      </Section>
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default withPrismicPreview(BlogPost);
