import styled from 'styled-components';
import { css } from '@styled-system/css';
import Button from './Button';

const LinkButton = styled(Button)(
  {
    cursor: 'pointer',
    appearance: 'none',
  },
  css({})
);

LinkButton.defaultProps = {
  as: 'a',
};

export default LinkButton;
