import * as React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import EmbedContainer from 'react-oembed-container';
import { Link as GatsbyLink } from 'gatsby';
import { Elements } from 'prismic-richtext';
import { Link as PrismicLink } from 'prismic-reactjs';

import { linkResolver } from './linkResolver';
import stringToSlug from './stringToSlug';
import innerText from './innerText';

import { Box, Flex, Container, Section, Link, Image, Heading, Text, Ul, Ol, Highlight, Icon } from '/components/system';

const IFrameContainer = styled(EmbedContainer)`
  iframe {
    max-width: 100%;
    max-height: 540px;
  }
`;

const iconPattern = /(:[\w-_,]+:)/;

const strInsertIcon = (n) => {
  if (typeof n === 'string' || n instanceof String) {
    return n
      .split(iconPattern)
      .map((p) =>
        iconPattern.test(p) ? (
          <Icon inline name={p.split(':')[1].split(',')[0]} color={p.split(':')[1].split(',')[1] || 'inherit'} />
        ) : (
          p
        )
      );
  } else {
    return n;
  }
};

const insertIcons = (children) => {
  return children.map((ic) => (Array.isArray(ic) ? ic.map(strInsertIcon) : strInsertIcon(ic)));
};

// -- HTML Serializer
export const htmlSerializerFactory = (props) => {
  // eslint-disable-next-line
  return (type, element, content, children, key) => {
    switch (type) {
      case Elements.heading1: // Heading 1
        return (
          <Heading as="h1" variant="800" key={key} id={stringToSlug(innerText(children))} {...props}>
            {insertIcons(children)}
          </Heading>
        );

      case Elements.heading2: // Heading 2
        return (
          <Heading as="h2" variant="700" key={key} id={stringToSlug(innerText(children))} {...props}>
            {insertIcons(children)}
          </Heading>
        );

      case Elements.heading3: // Heading 3
        return (
          <Heading as="h3" variant={['500', '600']} key={key} id={stringToSlug(innerText(children))} {...props}>
            {insertIcons(children)}
          </Heading>
        );

      case Elements.heading4: // Heading 4
        return (
          <Heading as="h4" variant={['400', '500']} key={key} {...props}>
            {insertIcons(children)}
          </Heading>
        );

      case Elements.heading5: // Heading 5
        return (
          <Heading as="h5" variant="400" key={key} {...props}>
            {insertIcons(children)}
          </Heading>
        );

      case Elements.heading6: // Heading 6
        return (
          <Heading as="h6" variant="300" key={key} {...props}>
            {insertIcons(children)}
          </Heading>
        );

      case Elements.paragraph: // Paragraph
        return (
          <Text variant="longBody" key={key} {...props}>
            {insertIcons(children)}
          </Text>
        );

      case Elements.preformatted: // Preformatted
        return (
          <pre key={key} {...props}>
            {children}
          </pre>
        );

      case Elements.strong: // Strong
        return (
          <strong key={key} {...props}>
            {children}
          </strong>
        );

      case Elements.em: // Emphasis
        return (
          <em key={key} {...props}>
            {children}
          </em>
        );

      case Elements.listItem: // Unordered List Item
      case Elements.oListItem: // Ordered List Item
        return <li key={key}>{insertIcons(children)}</li>;

      case Elements.list: // Unordered List
        return (
          <Ul my={0} key={key} fontSize={[2, 3]} lineHeight={'longBody'} {...props}>
            {insertIcons(children)}
          </Ul>
        );

      case Elements.oList: // Ordered List
        return (
          <Ol my={0} pl={7} key={key} fontSize={[2, 3]} lineHeight={'longBody'} {...props}>
            {insertIcons(children)}
          </Ol>
        );

      case Elements.image: {
        const linkUrl = element.linkTo ? element.linkTo.url || linkResolver(element.linkTo) : null;

        return (
          <p key={key} className={[element.label, 'block-img'].filter(Boolean).join(' ')}>
            {linkUrl ? (
              <a href={linkUrl} target={element.linkTo?.target} rel={element.linkTo?.target ? 'noopener' : undefined}>
                <Image src={element.url} alt={element.alt || ''} boxShadow="large" borderRadius={2} display="block" />
              </a>
            ) : (
              <Image src={element.url} alt={element.alt || ''} boxShadow="large" borderRadius={2} display="block" />
            )}
          </p>
        );
      }

      case Elements.embed: // Embed
        return (
          <IFrameContainer markup={element.oembed.html}>
            <div
              key={key}
              data-oembed={element.oembed.embed_url}
              data-oembed-type={element.oembed.type}
              data-oembed-provider={element.oembed.provider_name}
              className={element.label}
              dangerouslySetInnerHTML={{ __html: element.oembed.html }}
            />
          </IFrameContainer>
        );

      case Elements.hyperlink: {
        const url = PrismicLink.url(element.data, linkResolver).replace('https://#', '#');

        if (element.data.link_type === 'Document') {
          return (
            <Link variant="body" key={key} to={url} as={GatsbyLink} {...props}>
              {content}
            </Link>
          );
        }

        return (
          <Link
            variant="body"
            as="a"
            key={key}
            href={url}
            target={element.data.target}
            rel={element.data.target ? 'noopener' : undefined}
            {...props}
          >
            {children}
          </Link>
        );
      }

      case Elements.label: // Label
        return (
          <label key={key} className={element.data.label}>
            {children}
          </label>
        );

      case Elements.span: // Span
        if (content) {
          return content.split('\\n').reduce((acc, p) => {
            if (acc.length === 0) {
              return [p];
            } else {
              const brIndex = (acc.length + 1) / 2 - 1;
              return acc.concat([<br key={brIndex} />, p]);
            }
          }, []);
        } else {
          return null;
        }

      default:
        // Always include a default that returns null
        return null;
    }
  };
};

const htmlSerializer = htmlSerializerFactory();

export default htmlSerializer;
