import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { getImage } from 'gatsby-plugin-image';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

import htmlSerializer, { htmlSerializerFactory } from '/utils/htmlSerializer';
import { Flex, Section, GatsbyImage, Container, Blockquote, Text, VisibleVideo, Icon, Box } from '/components/system';
import { TestimonialCard } from '/components/Home/Testimonials';
import SignupButton from '../SignupButton';
import { containerStyles, sectionStyles, appImageStyles, RichTextType, PrismicRichText } from './shared';

const Feature = ({
  slice: {
    primary: {
      header,
      content,
      cta,
      image,
      video,
      social_proof_quote,
      social_proof_source_name,
      social_proof_source_title,
      social_proof_image,
    },
  },
  index,
}) => {
  const reverse = index % 2 === 0;

  const query = graphql`
    query VideoQuery {
      allFile(filter: { extension: { in: ["mp4", "webm"] } }) {
        nodes {
          name
          extension
          publicURL
        }
      }
    }
  `;
  const {
    allFile: { nodes: videos },
  } = useStaticQuery(query);
  const matchingVideos = videos.filter((v) => v.name === video);

  const hasPreview = matchingVideos.length > 0 || getImage(image);

  return (
    <Container {...containerStyles} minHeight={0}>
      <Section {...sectionStyles} flexDirection={reverse ? 'row-reverse' : 'row'}>
        <Flex
          flexBasis={['100%', null, '600px', null]}
          flexShrink={2}
          flexGrow={[0, null, 1, null]}
          mb={[12, 12, 0, 0]}
          flexDirection="column"
          justifyContent="center"
          textAlign={['left', null, null]}
        >
          <PrismicRichText content={header} variant="subheading" />
          <PrismicRichText content={content} />
          {cta && <SignupButton variant="secondary" text={cta} />}
          {hasPreview &&
            social_proof_quote &&
            social_proof_quote.richText &&
            social_proof_quote.richText[0] &&
            social_proof_quote.richText[0].text && (
              <TestimonialCard
                rotated
                testimonial={{
                  quote: social_proof_quote,
                  source_name: social_proof_source_name,
                  source_title: social_proof_source_title,
                  source_image: social_proof_image,
                }}
              />
            )}
        </Flex>
        <Flex
          flexBasis={['100%', null, '600px', null]}
          flexShrink={1}
          pb={[0, 0, 0, 6]}
          flexDirection={'column'}
          position={'relative'}
          alignItems={'center'}
          justifyContent="center"
        >
          {hasPreview ? (
            <Zoom>
              {matchingVideos.length > 0 ? (
                <VisibleVideo muted restartAfter={5000} playsInline preload="none" {...appImageStyles}>
                  {matchingVideos.map((v) => (
                    <source key={v.extension} src={v.publicURL} type={`video/${v.extension}`} />
                  ))}
                </VisibleVideo>
              ) : (
                <GatsbyImage {...appImageStyles} image={getImage(image)} />
              )}
            </Zoom>
          ) : (
            <TestimonialCard
              rotated
              rotation={'5deg'}
              maxWidth={'450px'}
              testimonial={{
                quote: social_proof_quote,
                source_name: social_proof_source_name,
                source_title: social_proof_source_title,
                source_image: social_proof_image,
              }}
            />
          )}
        </Flex>
      </Section>
    </Container>
  );
};

export const FeatureSliceType = PropTypes.shape({
  slice_type: PropTypes.oneOf(['feature']),
  primary: PropTypes.shape({
    header: PropTypes.object,
    content: RichTextType,
    cta: PropTypes.string,
    image: PropTypes.object,
    video: PropTypes.string,
    social_proof_quote: RichTextType,
    social_proof_source_name: PropTypes.string,
    social_proof_source_title: PropTypes.string,
    social_proof_image: PropTypes.object,
  }),
});

Feature.propTypes = {
  slice: FeatureSliceType,
  index: PropTypes.number,
};

export default Feature;
