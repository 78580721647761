import PropTypes from 'prop-types';
import React from 'react';
import { css } from '@styled-system/css';
import { Flex, Heading, Text, Icon } from './system';

const TabStyles = (props) =>
  css({
    '&:hover': {
      opacity: '1',
    },
    cursor: 'pointer',
  });

const CarouselTab = ({ header, subheader, color = 'text.default', isActive, icon, ...props }) => (
  <Flex
    opacity={isActive ? '1' : '.5'}
    css={TabStyles}
    cursor={'pointer'}
    flexDirection="column"
    alignItems={['center', null, 'flex-start']}
    justifyContent="flex-start"
    px={5}
    py={3}
    flexGrow={1}
    isActive={isActive}
    borderRadius={2}
    width={[1]}
    {...props}
    display={[!isActive ? 'none' : 'flex', 'null', 'flex']}
  >
    <Flex flexDirection="row" alignItems="center">
      {icon && <Icon name={icon} color={color} mr={3} mt={1} />}
      <Heading variant="600" textAlign="center" color={color} pr={3} mb={0}>
        {header}
      </Heading>
    </Flex>
    {subheader && (
      <Text variant="400" textAlign={['center', null, 'left']}>
        {subheader}
      </Text>
    )}
  </Flex>
);

CarouselTab.propTypes = {
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string,
  icon: PropTypes.string,
  isActive: PropTypes.bool,
  color: PropTypes.string,
};

export default CarouselTab;
