import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React from 'react';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { RichText } from 'prismic-reactjs';
import css from '@styled-system/css';
import {
  TwitterShareButton,
  TwitterIcon,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
} from 'react-share';

import { Layout, Navbar, Header } from '/components';
import {
  Text,
  Container,
  Section,
  Flex,
  Label,
  Box,
  Link,
  LinkButton,
  Button,
  Heading,
  GatsbyImage,
  Image,
  GridArea,
  Highlight,
  Blockquote,
  Icon,
  Video,
  Ul,
} from '/components/system';
import SliceZone from '/components/prismic/SliceZone';

export const query = graphql`
  query MyQuery($id: String) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    prismicLandingPage(id: { eq: $id }) {
      _previewable
      data {
        navbar_cta
        navbar_cta_link {
          url
        }
        page_title
        page_description
        seo_image {
          url
        }
        show_community_links
        show_product_links
        no_index
        body {
          ... on PrismicLandingPageDataBodyHero {
            id
            slice_label
            slice_type
            primary {
              cta
              cta_link {
                url
              }
              subheader {
                richText
              }
              header {
                richText
              }
              image {
                gatsbyImageData
              }
              video
              hide_social_proof
              hide_shadow
            }
          }
          ... on PrismicLandingPageDataBodyFeatureTestimonials {
            id
            primary {
              title {
                richText
              }
            }
            items {
              company_logo {
                gatsbyImageData
              }
              name {
                richText
              }
              profile_pic {
                gatsbyImageData
              }
              quote {
                richText
              }
              role_position {
                richText
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicLandingPageDataBodyFeature {
            id
            slice_label
            slice_type
            primary {
              cta
              content {
                richText
              }
              header {
                richText
              }
              image {
                gatsbyImageData
              }
              video
              social_proof_quote {
                richText
              }
              social_proof_source_name
              social_proof_source_title
              social_proof_image {
                gatsbyImageData
              }
            }
          }
          ... on PrismicLandingPageDataBodyFeatures {
            id
            slice_type
            items {
              content {
                richText
              }
              header {
                richText
              }
              image {
                gatsbyImageData
              }
              tab_text
              testimonial {
                document {
                  ... on PrismicTestimonials {
                    id
                    data {
                      quote {
                        richText
                      }
                      source_image {
                        gatsbyImageData
                      }
                      source_name
                      source_title
                    }
                    _previewable
                  }
                }
              }
              video
              hide_shadow
            }
            primary {
              header {
                richText
              }
            }
          }
          ... on PrismicLandingPageDataBodyFaqSection {
            id
            slice_label
            slice_type
            primary {
              header {
                richText
              }
              content {
                richText
              }
            }
            items {
              answer {
                richText
              }
              question {
                richText
              }
            }
          }
          ... on PrismicLandingPageDataBodyCallToAction {
            id
            primary {
              cta
              cta_link {
                url
              }
              tilted
              header {
                richText
              }
              subheader {
                richText
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicLandingPageDataBodyFullWidthVideo {
            id
            primary {
              video
            }
            slice_type
          }
          ... on PrismicLandingPageDataBodyEmbeddedNotionPage {
            id
            primary {
              notion_page_id
            }
            slice_type
          }
          ... on PrismicLandingPageDataBodyEmbeddedtypeform {
            id
            primary {
              section_header {
                richText
              }
              section_body {
                richText
              }
              typeform_id
              linkedin_conversion_id
            }
            slice_type
          }
          ... on PrismicLandingPageDataBodyImageGallery {
            id
            primary {
              section_title {
                richText
              }
              section_body {
                richText
              }
              background_color
            }
            items {
              image {
                gatsbyImageData
              }
              link {
                url
              }
            }
            slice_type
          }
          ... on PrismicLandingPageDataBodyExistingSection {
            id
            primary {
              linked_section {
                document {
                  ... on PrismicLandingPageSection {
                    id
                    _previewable
                    data {
                      body {
                        ... on PrismicLandingPageSectionDataBodySectionColumn {
                          id
                          slice_type
                          primary {
                            icon
                            column_title {
                              richText
                            }
                            column_content {
                              richText
                            }
                            cta_text
                            cta_link {
                              url
                            }
                          }
                          items {
                            icon
                            name
                          }
                          slice_label
                        }
                      }
                      title {
                        richText
                      }
                      content {
                        richText
                      }
                      image {
                        gatsbyImageData
                      }
                      hide_image_shadow
                      testimonial {
                        document {
                          ... on PrismicTestimonials {
                            id
                            data {
                              quote {
                                richText
                              }
                              source_image {
                                gatsbyImageData
                              }
                              source_name
                              source_title
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              preprogrammed_section
            }
            slice_label
            slice_type
          }
        }
      }
    }
  }
`;

const LandingPage = ({ location, data: { site, prismicLandingPage: document } }) => {
  const siteUrl = site && site.siteMetadata && site.siteMetadata.siteUrl;
  const canonicalUrl = siteUrl + location.pathname;

  return (
    <Layout
      seoTitle={document.data.page_title}
      seoDescription={document.data.page_description}
      seoImage={document.data.seo_image?.url}
      insideContainer={false}
      showNav={true}
      navbarProps={{
        signupButtonText: document.data.navbar_cta || 'Try Heyday',
        signupButtonLink: document.data.navbar_cta_link?.url,
        showCommunityLinks: document.data.show_community_links,
        showProductLinks: document.data.show_product_links,
      }}
    >
      <Helmet>
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:published_time" content={document.first_publication_date} />
        <meta property="og:modified_time" content={document.last_publication_date} />
        {document.data.no_index && <meta name="robots" content="noindex" />}
      </Helmet>
      <SliceZone slices={document.data.body} />
    </Layout>
  );
};

LandingPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default withPrismicPreview(LandingPage);
