import styled from 'styled-components';
import { variant } from 'styled-system';
import { composeStyleProps } from './shared';

const Heading = styled('h2')(
  variant({
    scale: 'variants.headings',
    variants: {
      default: {},
    },
  }),
  composeStyleProps
);

Heading.defaultProps = {
  variant: '900',
};

export default Heading;
