import styled from 'styled-components';
import { css } from '@styled-system/css';

import { composeStyleProps } from './shared';

const Video = styled('video')(composeStyleProps, {}, css({}));

Video.defaultProps = {};

export default Video;
