import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Airtable from 'airtable';

import { Button, Flex, Input } from '/components/system';

const base = new Airtable({ apiKey: 'keyNLQnbKwFjjbWrc' }).base('app1aA6rXQvTNPqqz');
const subscribersTable = base('Blog Subscribers');

const SubscribeButton = (props) => {
  const [email, setEmail] = useState('');
  const [buttonText, setButtonText] = useState('Subscribe');

  const onSubmit = () => {
    setButtonText('Subscribing...');
    subscribersTable.create(
      [
        {
          fields: { Email: email },
        },
      ],
      (err, record) => {
        setButtonText('Subscribed!');
      }
    );
  };

  return (
    <Flex
      my={5}
      borderRadius={2}
      border="1px solid"
      borderColor="pallete.neutral.medium"
      overflow="hidden"
      flex="0 1 auto"
      {...props}
    >
      <Input
        p={4}
        height={48}
        placeholder="sam@heyday.xyz"
        fontSize={[2, 3]}
        border={0}
        borderRadius={'8px 0 0 8px'}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button
        variant="primary"
        display="inline"
        disabled={buttonText !== 'Subscribe'}
        borderRadius={0}
        m={0}
        my={0}
        height={48}
        py={2}
        fontSize={[2, 3]}
        px={[4, 10]}
        onClick={onSubmit}
        css={{
          '&[disabled]': {
            opacity: 0.65,
            cursor: 'not-allowed',
          },
        }}
      >
        {buttonText}
      </Button>
    </Flex>
  );
};

export default SubscribeButton;
