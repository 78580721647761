module.exports = (doc) => {
  // URL for a category type
  if (doc.type === 'ama_guests') {
    return `/community/guests/${doc.uid}/`;
  }

  if (doc.type === 'blog_post') {
    return `/blog/${doc.uid}/`;
  }

  // URL for a page type
  if (doc.type === 'community_page') {
    return `/community/`;
  }

  if (doc.type === 'landing_page' && doc.uid === 'home') {
    return `/`;
  }

  if (doc.type === 'landing_page') {
    return `/${doc.uid}/`;
  }

  if (doc.type === 'about_page') {
    return `/about`;
  }

  // Backup for all other types
  return '/';
};
