exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about/[...].js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-prismic-blog-post-uid-js": () => import("./../../../src/pages/blog/{PrismicBlogPost.uid}.js" /* webpackChunkName: "component---src-pages-blog-prismic-blog-post-uid-js" */),
  "component---src-pages-copyright-mdx": () => import("./../../../src/pages/copyright.mdx" /* webpackChunkName: "component---src-pages-copyright-mdx" */),
  "component---src-pages-help-ios-safari-js": () => import("./../../../src/pages/help/ios-safari.js" /* webpackChunkName: "component---src-pages-help-ios-safari-js" */),
  "component---src-pages-payment-index-js": () => import("./../../../src/pages/payment/index.js" /* webpackChunkName: "component---src-pages-payment-index-js" */),
  "component---src-pages-payment-success-js": () => import("./../../../src/pages/payment/success.js" /* webpackChunkName: "component---src-pages-payment-success-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-prismic-landing-page-uid-js": () => import("./../../../src/pages/{PrismicLandingPage.uid}.js" /* webpackChunkName: "component---src-pages-prismic-landing-page-uid-js" */),
  "component---src-pages-privacy-mdx": () => import("./../../../src/pages/privacy.mdx" /* webpackChunkName: "component---src-pages-privacy-mdx" */),
  "component---src-pages-secure-development-mdx": () => import("./../../../src/pages/secure_development.mdx" /* webpackChunkName: "component---src-pages-secure-development-mdx" */),
  "component---src-pages-terms-mdx": () => import("./../../../src/pages/terms.mdx" /* webpackChunkName: "component---src-pages-terms-mdx" */)
}

