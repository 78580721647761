import React, { useRef, useCallback, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

import Video from './Video';

const VisibleVideo = (props) => {
  const { children, playbackRate, restartAfter, ...rest } = props;

  const videoRef = useRef();
  const { ref, inView } = useInView({
    threshold: 0.5,
    trackVisibility: true,
    delay: 1000,
  });

  const setRefs = useCallback(
    (node) => {
      // Ref's from useRef needs to have the node assigned to `current`
      videoRef.current = node;
      // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
      ref(node);
    },
    [ref]
  );

  useLayoutEffect(() => {
    videoRef.current?.load();
    if (inView) {
      videoRef.current.playbackRate = playbackRate || 1.0;
      videoRef.current?.play();
    }
  }, [children, inView]);

  useLayoutEffect(() => {
    if (inView) {
      videoRef.current.playbackRate = playbackRate || 1.0;
      videoRef.current?.play();
    } else {
      videoRef.current?.pause();
      videoRef.current.currentTime = 0;
    }
  }, [inView]);

  const onEnded = useCallback(() => {
    if (restartAfter) {
      setTimeout(() => {
        videoRef.current?.play();
      }, restartAfter);
    }
  }, []);

  return (
    <Video ref={setRefs} onEnded={onEnded} {...props}>
      {children}
    </Video>
  );
};

VisibleVideo.propTypes = {
  children: PropTypes.node,
  playbackRate: PropTypes.number,
  restartAfter: PropTypes.number,
};

export default VisibleVideo;
