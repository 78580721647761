import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';

import { AnimatedFlex, Flex, Text, Icon, Button } from '/components/system';

const ACItemProps = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

const AccordionItem = ({ title, content, onClick, isOpen }) => {
  return (
    <AnimatedFlex
      layout
      key={`${title}:wrapper`}
      transition={{ default: { duration: 0.2 } }}
      flexDirection="column"
      py={3}
    >
      <AnimatedFlex
        layout
        key={`${title}:title`}
        transition={{ default: { duration: 0.2 } }}
        px={6}
        py={2}
        alignItems="center"
        justifyContent={'space-between'}
        onClick={onClick}
        bg="background.blue"
        borderRadius={32}
      >
        <Text variant={'body'} color="white" fontWeight={'600'}>
          {title}
        </Text>
        <Icon name={'arrow-d'} transform={isOpen && `rotate(-180deg)`} size="600" color="white" />
      </AnimatedFlex>
      <AnimatePresence initial={false}>
        {isOpen && (
          <AnimatedFlex
            layout
            overflow="hidden"
            key={`${title}:content`}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ default: { duration: 0.2 } }}
            ml={5}
          >
            <Text mt={4} mb={0} variant={'longBody'}>
              {content}
            </Text>
          </AnimatedFlex>
        )}
      </AnimatePresence>
    </AnimatedFlex>
  );
};

AccordionItem.propTypes = ACItemProps;

const Accordion = ({ sections }) => {
  const [openItems, setItems] = useState(new Set([0]));

  const toggleItem = (idx) => {
    if (openItems.has(idx)) {
      openItems.delete(idx);
      setItems(new Set(openItems));
    } else {
      setItems(new Set(openItems.add(idx)));
    }
  };

  return (
    <AnimatedFlex
      key={`accordion`}
      layout
      transition={{ default: { duration: 0.2 } }}
      flexDirection="column"
      width={720}
      maxWidth={'100%'}
    >
      <LayoutGroup>
        {sections.map((s, idx) => (
          <AccordionItem
            title={s.title}
            content={s.content}
            isOpen={openItems.has(idx)}
            onClick={() => toggleItem(idx)}
            key={idx}
          />
        ))}
      </LayoutGroup>
    </AnimatedFlex>
  );
};

Accordion.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape(ACItemProps)),
};

export default Accordion;
