import PropTypes from 'prop-types';
import React from 'react';
import { Container, Section } from './system';

const Header = ({ children, ...props }) => (
  <Section mt={0} width={1} pt={0} pb={0} justifyContent={['center', 'space-between']} flex="0 0 auto">
    {children}
  </Section>
);

Header.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Header;
