import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import { getImage, StaticImage } from 'gatsby-plugin-image';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import styled from 'styled-components';

import htmlSerializer, { htmlSerializerFactory } from '/utils/htmlSerializer';
import { sectionStyles, appImageStyles, RichTextType, PrismicRichText } from './shared';
import {
  Container,
  Section,
  Flex,
  Box,
  Heading,
  Text,
  SVG,
  GatsbyImage,
  Video,
  Icon,
  Card,
  Link,
  Button,
} from '/components/system';
import { TestimonialCard, TestimonialType } from '/components/Home/Testimonials';

const GridBox = styled(Box)`
  display: grid;
  justify-content: space-between;
`;

const iconColors = ['secondary1', 'blue', 'secondary2', 'peach', 'yellow'];

const LandingPageColumn = ({ items, column_title, column_content, cta_text, cta_link, column_icon, idx }) => (
  <Flex flexDirection="column" justifyContent="space-between">
    <Box>
      {column_icon && (
        <Box>
          <Icon name={column_icon} color={iconColors[idx]} height={[48, 64]} />
        </Box>
      )}

      <Box borderTop="1px solid black" my={4}>
        <PrismicRichText content={column_title} variant="subheading" fontSize={[3, 4]} mb={0} mt="-0.125em" />
      </Box>
      <PrismicRichText content={column_content} variant="body" />
    </Box>
    {items && items.length > 0 && (
      <GridBox my={4} gridTemplateColumns={`repeat(2, minmax(auto, 1fr))`} gridRowGap={[6, 10]}>
        {items.map(({ icon, name }) => (
          <Flex key={name} flexDirection="column" alignItems="center" justifyContent="space-around" gridGap={4}>
            {icon && <Icon inline size={[32, 48]} name={icon} color={'black'} />}
            <Text variant="sublabel" textAlign="center">
              {name}
            </Text>
          </Flex>
        ))}
      </GridBox>
    )}
    {cta_text && cta_link && cta_link.url && (
      <Box mt={2}>
        <Link href={cta_link.url} target="_blank">
          <Button variant="outline" fontWeight="500">
            {cta_text}
          </Button>
        </Link>
      </Box>
    )}
  </Flex>
);

LandingPageColumn.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({ icon: PropTypes.string, name: PropTypes.string })),
  column_title: RichTextType,
  column_content: RichTextType,
  cta_text: PropTypes.string,
  cta_link: PropTypes.shape({
    url: PropTypes.string,
  }),
  column_icon: PropTypes.string,
  idx: PropTypes.number,
};

const LandingPageRow = ({ items, column_title, column_content, cta_text, cta_link, column_icon, idx }) => (
  <Flex flexDirection="column" justifyContent="space-between">
    <Box>
      <Flex alignItems="center" my={4} gap={2}>
        {column_icon && <Icon name={column_icon} color={iconColors[idx]} height={[32, 40]} />}
        <PrismicRichText content={column_title} variant="subheading" fontSize={[3, 4]} mb={0} mt="-0.125em" />
      </Flex>
      <PrismicRichText content={column_content} variant="body" />
    </Box>
    {items && items.length > 0 && (
      <GridBox my={4} gridTemplateColumns={`repeat(2, minmax(auto, 1fr))`} gridRowGap={[6, 10]}>
        {items.map(({ icon, name }) => (
          <Flex key={name} flexDirection="column" alignItems="center" justifyContent="space-around" gridGap={4}>
            {icon && <Icon inline size={[32, 48]} name={icon} color={'black'} />}
            <Text variant="sublabel" textAlign="center">
              {name}
            </Text>
          </Flex>
        ))}
      </GridBox>
    )}
    {cta_text && cta_link && cta_link.url && (
      <Box mt={2}>
        <Link href={cta_link.url} target="_blank">
          <Button variant="outline" fontWeight="500">
            {cta_text}
          </Button>
        </Link>
      </Box>
    )}
  </Flex>
);

LandingPageRow.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({ icon: PropTypes.string, name: PropTypes.string })),
  column_title: RichTextType,
  column_content: RichTextType,
  cta_text: PropTypes.string,
  cta_link: PropTypes.shape({
    url: PropTypes.string,
  }),
  column_icon: PropTypes.string,
  idx: PropTypes.number,
};

const LandingPageSection = ({
  section: {
    data: { body: columns, title, content, testimonial, image, hide_image_shadow = false },
  },
}) => {
  return (
    <Container overflow={'hidden'}>
      <Section {...sectionStyles}>
        <Flex flexDirection="column" width={'100%'} maxWidth={'1200px'}>
          <Flex
            mb={6}
            justifyContent="space-between"
            alignItems={['start', 'center']}
            flexDirection={['column', 'row']}
          >
            <PrismicRichText content={title} mb={0} variant="primary" />
            {testimonial.document && (
              <TestimonialCard
                rotated
                flex={[null, '0 1 400px']}
                mt={[5, null]}
                testimonial={testimonial.document.data}
              />
            )}
          </Flex>
          <PrismicRichText content={content} maxWidth={'720px'} />
          {image && image.gatsbyImageData ? (
            <Flex flexDirection={['column', null, 'row', null]} alignItems="center" gridGap={[2, 4]}>
              <GridBox
                flex={[null, '0 0 400px']}
                width={['100%', null, '400px', null]}
                gridGap={[2, 4]}
                my={6}
                gridTemplateColumns={`repeat(auto-fit, minmax(260px, 1fr))`}
              >
                {columns.map(
                  (
                    { id, items, primary: { column_title, column_content, cta_text, cta_link, icon: column_icon } },
                    idx
                  ) => (
                    <LandingPageRow
                      key={id}
                      items={items}
                      column_title={column_title}
                      column_content={column_content}
                      cta_text={cta_text}
                      cta_link={cta_link}
                      column_icon={column_icon}
                      idx={idx}
                    />
                  )
                )}
              </GridBox>
              <Box>
                <Zoom>
                  <GatsbyImage
                    {...appImageStyles}
                    image={getImage(image)}
                    boxShadow={hide_image_shadow ? undefined : 'large'}
                  />
                </Zoom>
              </Box>
            </Flex>
          ) : (
            <GridBox gridGap={[6, 12]} my={6} gridTemplateColumns={`repeat(auto-fit, minmax(260px, 1fr))`}>
              {columns.map(
                (
                  { id, items, primary: { column_title, column_content, cta_text, cta_link, icon: column_icon } },
                  idx
                ) => (
                  <LandingPageColumn
                    key={id}
                    items={items}
                    column_title={column_title}
                    column_content={column_content}
                    cta_text={cta_text}
                    cta_link={cta_link}
                    column_icon={column_icon}
                    idx={idx}
                  />
                )
              )}
            </GridBox>
          )}
        </Flex>
      </Section>
    </Container>
  );
};

export const LandingPageSectionColumnType = PropTypes.shape({
  id: PropTypes.string,
  primary: {
    icon: PropTypes.string,
    column_title: RichTextType,
    column_content: RichTextType,
    cta_text: PropTypes.string,
    cta_link: PropTypes.shape({
      url: PropTypes.string,
    }),
  },
  items: PropTypes.arrayOf(PropTypes.shape({ icon: PropTypes.string, name: PropTypes.string })),
});

export const LandingPageSectionType = PropTypes.shape({
  data: {
    body: PropTypes.arrayOf(LandingPageSectionColumnType),
    title: RichTextType,
    content: RichTextType,
    testimonial: {
      document: {
        data: TestimonialType,
      },
    },
    image: PropTypes.object,
    hide_image_shadow: PropTypes.bool,
  },
});

LandingPageSection.propTypes = {
  section: LandingPageSectionType,
};

export default LandingPageSection;
