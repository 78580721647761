import React from 'react';
import PropTypes from 'prop-types';
import { Widget } from '@typeform/embed-react';
import { RichTextType, PrismicRichText } from './shared';
import useGrowsurf from '/hooks/useGrowsurf';

import { Section, Flex, Container, Heading, Text } from '/components/system';

const EmbeddedTypeform = ({
  slice: {
    primary: { typeform_id, linkedin_conversion_id, section_header, section_body },
  },
}) => {
  const growsurf = useGrowsurf('ydfhqk');

  return (
    <Container
      id={`typeform-${typeform_id}`}
      position="relative"
      display="flex"
      mb={5}
      py={[8, 8, 6, 6]}
      px={[8, 10, 12, 12]}
      flexDirection="column"
      bg="background.dark"
    >
      <Section width={1} py={6} justifyContent="flex-start" alignItems="start" flexDirection="column">
        <Flex
          flex={'1 1 auto'}
          maxWidth={'720px'}
          pb={[6]}
          flexDirection="column"
          justifyContent="center"
          width={[1, 1, 0.8, 0.7, null]}
        >
          {section_header && <PrismicRichText content={section_header} my={8} variant="primary" color="text.light" />}
          {section_body && <PrismicRichText content={section_body} color="text.light" />}
          <Flex flexDirection="row" minHeight={['768px', null, '576px']}>
            <Widget
              id={typeform_id}
              hideHeaders={true}
              hideHeader={true}
              opacity={0}
              style={{ flex: '1 1 auto' }}
              inlineOnMobile={true}
              transitiveSearchParams={['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term', 'grsf']}
              onSubmit={({ formId, responseId }) => {
                // TODO: Should probably fire a Segment event here.
                if (window && window.lintrk && linkedin_conversion_id) {
                  window.lintrk('track', { conversion_id: linkedin_conversion_id });
                }
              }}
            />
          </Flex>
        </Flex>
      </Section>
    </Container>
  );
};

export const EmbeddedTypeformSliceType = PropTypes.shape({
  slice_type: PropTypes.oneOf(['embeddedtypeform']),
  primary: {
    typeform_id: PropTypes.string,
    linkedin_conversion_id: PropTypes.number,
    section_header: RichTextType,
    section_body: RichTextType,
  },
});

EmbeddedTypeform.propTypes = {
  slice: EmbeddedTypeformSliceType,
};

export default EmbeddedTypeform;
