import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { getImage } from 'gatsby-plugin-image';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import htmlSerializer, { htmlSerializerFactory } from '/utils/htmlSerializer';
import { Box, Flex, Section, Image, GatsbyImage, Container, VisibleVideo, Grid, Link, Text } from '/components/system';
import BusinessInsiderLogo from '/assets/images/businessInsiderLogo.inline.svg';
import SignupButton from '../SignupButton';
import { containerStyles, sectionStyles, appImageStyles, RichTextType, PrismicRichText } from './shared';

const Hero = ({
  slice: {
    primary: {
      header,
      subheader,
      cta,
      cta_link: { url: ctaUrl },
      image,
      video,
      hide_social_proof,
      hide_shadow,
    },
  },
}) => {
  const query = graphql`
    query {
      fastCompanyLogo: file(relativePath: { eq: "fastCompanyLogo.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], transformOptions: { fit: INSIDE })
        }
      }
      morningBrewLogo: file(relativePath: { eq: "morningBrewLogo.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], transformOptions: { fit: INSIDE })
        }
      }
      allVideos: allFile(filter: { extension: { in: ["mp4", "webm"] } }) {
        nodes {
          name
          extension
          publicURL
        }
      }
      allJpegs: allFile(filter: { extension: { in: ["jpg"] } }) {
        nodes {
          name
          extension
          publicURL
        }
      }
    }
  `;
  const {
    allVideos: { nodes: videos },
    allJpegs: { nodes: jpegs },
    fastCompanyLogo,
    morningBrewLogo,
  } = useStaticQuery(query);
  const matchingVideos = videos.filter((v) => v.name === video);
  const matchingImages = jpegs.filter((v) => v.name === video);

  return (
    <Container {...containerStyles}>
      <Section {...sectionStyles} pt={4} pb={4}>
        <Flex
          flexBasis={['100%', null, '540px', null]}
          flexShrink={1}
          flexGrow={[0, null, 1, null]}
          mb={[12, 12, 0, 0]}
          flexDirection="column"
          justifyContent="center"
          textAlign={['left', null, null]}
        >
          <PrismicRichText content={header} variant="primary" />
          <PrismicRichText content={subheader} variant="body" />
          <SignupButton variant="primary" text={cta} href={ctaUrl} />

          {!hide_social_proof && (
            <Flex flexDirection="column" data-nosnippet>
              <Text variant="label" fontWeight="600" color="text.muted">
                AS SEEN IN
              </Text>
              <Grid maxWidth="480px" gridTemplateColumns="1fr 1fr 1.2fr" gridGap={[3, 4]} alignItems="center">
                <Link
                  target="_blank"
                  href="https://www.fastcompany.com/90716218/this-clever-app-makes-you-way-more-productive-automatically"
                >
                  <GatsbyImage image={getImage(fastCompanyLogo)} />
                </Link>
                <Link
                  target="_blank"
                  href="https://www.businessinsider.com/i-outsourced-my-memory-remember-what-you-read-using-ai-2023-1"
                >
                  <BusinessInsiderLogo style={{ width: '100%' }} />
                </Link>
                <Link href="https://www.producthunt.com/newsletter/10719-close-those-tabs" target="_blank">
                  <Image
                    src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=338816&theme=light&period=monthly"
                    height={['32px', '42px']}
                  />
                </Link>
              </Grid>
            </Flex>
          )}
        </Flex>
        <Flex
          flexBasis={['100%', null, 'auto', null]}
          flexShrink={1}
          pb={[0, 0, 0, 6]}
          minHeight={['unset', '564px', null, null]}
          flexDirection={'column'}
          position={'relative'}
          alignItems={['center', null, 'flex-start']}
          justifyContent="center"
        >
          <Zoom>
            {matchingVideos.length > 0 ? (
              <VisibleVideo
                muted
                restartAfter={5000}
                playsInline
                preload="metadata"
                {...appImageStyles}
                maxWidth={['100vw', null, '50vw']}
                boxShadow={hide_shadow ? undefined : 'large'}
                poster={matchingImages && matchingImages.length > 0 ? matchingImages[0].publicURL : null}
              >
                {matchingVideos.map((v) => (
                  <source key={v.extension} src={`${v.publicURL}#t=0.1`} type={`video/${v.extension}`} />
                ))}
              </VisibleVideo>
            ) : (
              <GatsbyImage {...appImageStyles} image={getImage(image)} boxShadow={hide_shadow ? undefined : 'large'} />
            )}
          </Zoom>
        </Flex>
      </Section>
    </Container>
  );
};

export const HeroSliceType = PropTypes.shape({
  slice_type: PropTypes.oneOf(['hero']),
  primary: PropTypes.shape({
    header: PropTypes.object,
    subheader: RichTextType,
    cta: PropTypes.string,
    cta_link: PropTypes.shape({
      url: PropTypes.string,
    }),
    image: PropTypes.object,
    video: PropTypes.string,
    hide_social_proof: PropTypes.bool,
    hide_shadow: PropTypes.bool,
  }),
});

Hero.propTypes = {
  slice: HeroSliceType,
};

export default Hero;
