import styled from 'styled-components';
import { css } from '@styled-system/css';

import { composeStyleProps } from './shared';

const IFrame = styled('iframe')(composeStyleProps, {}, css({}));

IFrame.defaultProps = {};

export default IFrame;
