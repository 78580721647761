import colors from '../colors';

export default {
  default: {
    color: colors.pallete.neutral.dark,
    // background: colors.pallete.neutral.light,
    position: 'relative',
    my: [5, 6],
    mx: 'auto',
    pl: [7, 8],
    '&:before': {
      fontFamily: 'Arial',
      content: `'\\201C'`,
      position: 'absolute',
      fontSize: 7,
      left: '0px',
      top: '-10px',
    },
  },
  naked: {
    pl: [5, 6],
    ml: 0,
    mr: [5, 6],
    borderLeft: '2px solid',
    borderLeftColor: 'brand',
  },
};
