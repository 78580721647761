import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getImage } from 'gatsby-plugin-image';
import htmlSerializer, { htmlSerializerFactory } from '/utils/htmlSerializer';
import { Box, Flex, Section, GatsbyImage, Container, Text, Icon } from '/components/system';
import { Carousel } from '/components';
import { sectionStyles, RichTextType, PrismicRichText } from './shared';

const bgColors = ['background.secondary1tint', 'background.secondary2tint', 'background.brandTint', 'background.peach'];

const Testimonial = ({ testimonial: { company_logo, name, profile_pic, quote, role_position }, index }) => {
  return (
    <Flex
      borderRadius={4}
      boxShadow={'large'}
      bg={bgColors[index % bgColors.length]}
      flexDirection="column"
      py={4}
      px={8}
      width={[1, null, '400px', '500px']}
      maxWidth={'500px'}
      justifyContent={'space-between'}
    >
      {company_logo && getImage(company_logo) && (
        <Flex height={128} width={'100%'} flexDirection="column">
          <GatsbyImage
            image={getImage(company_logo)}
            maxWidth={'200px'}
            alignSelf="center"
            my={10}
            maxHeight={'48px'}
            objectFit="contain"
          />
        </Flex>
      )}
      <PrismicRichText content={quote} />
      <Flex mt={8}>
        <Box borderRadius={900} overflow="hidden" height="48px">
          {getImage(profile_pic) ? (
            <GatsbyImage
              image={getImage(profile_pic)}
              width={48}
              height={48}
              objectPosition="center center"
              objectFit="cover"
            />
          ) : (
            <Icon name="user" size={44} color="black" borderRadius={900} overflow="hidden" border={'2px solid black'} />
          )}
        </Box>
        <Box ml={2}>
          <Text fontWeight="600" lineHeight={'line'} mb={1}>
            {name.richText[0].text}
          </Text>
          {role_position && role_position.richText[0] && <Text>{role_position.richText[0].text}</Text>}
        </Box>
      </Flex>
    </Flex>
  );
};

const TestimonialType = PropTypes.shape({
  company_logo: PropTypes.object,
  name: RichTextType,
  profile_pic: PropTypes.object,
  quote: RichTextType,
  role_position: RichTextType,
});

Testimonial.propTypes = {
  testimonial: TestimonialType,
  index: PropTypes.number,
};

const Testimonials = ({
  slice: {
    primary: { title },
    items,
  },
}) => {
  return (
    <Container>
      <Section {...sectionStyles}>
        <Flex flexDirection="column" gap={10} maxWidth={'100%'}>
          <PrismicRichText content={title} mb={0} variant="primary" />
          <Carousel>
            {[...items, ...items].map((testimonial, index) => (
              <Testimonial testimonial={testimonial} key={`testimonial:${index}`} index={index} />
            ))}
          </Carousel>
        </Flex>
      </Section>
    </Container>
  );
};

export const TestimonialsSliceType = PropTypes.shape({
  slice_type: PropTypes.oneOf(['feature_testimonials']),
  primary: {
    title: RichTextType,
  },
  items: TestimonialType,
});

Testimonials.propTypes = {
  slice: TestimonialsSliceType,
};

export default Testimonials;
