import React from 'react';
import PropTypes from 'prop-types';
import { Card, Box } from './system';

const Embed = ({ children, ...props }) => (
  <Card {...props}>
    <Box
      css={{
        position: 'relative',
        overflow: 'hidden',
        'padding-top': '56.25%',
        '& > .react-player': {
          position: 'absolute',
          top: 0,
          left: 0,
        },
      }}
    >
      {children}
    </Box>
  </Card>
);

Embed.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Embed;
