import styled from 'styled-components';
import { variant } from 'styled-system';
import { composeStyleProps } from './shared';

const Text = styled('p')(
  variant({
    scale: 'variants.text',
    variants: {
      default: {},
    },
  }),
  composeStyleProps
);

Text.defaultProps = {
  variant: '400',
  mt: '0.75em',
  mb: '0.75em',
};

export default Text;
