import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import { RichTextType } from './shared';
import htmlSerializer, { htmlSerializerFactory } from '/utils/htmlSerializer';

const GenericContent = ({
  slice: {
    primary: { content },
  },
}) => <RichText render={content.richText || content.raw} htmlSerializer={htmlSerializer} />;

export const GenericContentSliceType = PropTypes.shape({
  slice_type: PropTypes.oneOf(['generic_content']),
  primary: PropTypes.shape({
    content: RichTextType,
  }),
});

GenericContent.propTypes = {
  slice: GenericContentSliceType,
};

export default GenericContent;
