import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { Container, VisibleVideo } from '/components/system';

const FullWidthVideo = ({
  slice: {
    primary: { video },
  },
}) => {
  const query = graphql`
    query {
      allFile(filter: { extension: { in: ["mp4", "webm"] } }) {
        nodes {
          name
          extension
          publicURL
        }
      }
    }
  `;
  const {
    allFile: { nodes: videos },
  } = useStaticQuery(query);

  const matchingVideos = videos.filter((v) => v.name === video);

  return (
    <Container overflow={'hidden'} px={0} display={['none', null, 'flex']}>
      <VisibleVideo
        muted
        playsInline
        playbackRate={1.25}
        controls
        controlslist="nodownload noremoteplayback"
        disablepictureinpicture
        preload="metadata"
        width="100%"
      >
        {matchingVideos.map((v) => (
          <source key={v.extension} src={v.publicURL} type={`video/${v.extension}`} />
        ))}
      </VisibleVideo>
    </Container>
  );
};

export const FullWidthVideoSliceType = PropTypes.shape({
  slice_type: PropTypes.oneOf(['full_width_video']),
  primary: {
    video: PropTypes.string,
  },
});

FullWidthVideo.propTypes = {
  slice: FullWidthVideoSliceType,
};

export default FullWidthVideo;
