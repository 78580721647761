import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import { RichTextType } from './shared';
import htmlSerializer, { htmlSerializerFactory } from '/utils/htmlSerializer';
import stringToSlug from 'utils/stringToSlug';
import { Link, Ul } from '/components/system';

const LinkedListItem = ({ item }) => (
  <li>
    <Link variant="body" as="a" href={`#${stringToSlug(item.link_title)}`}>
      {item.link_title}
    </Link>
    &nbsp;{item.description}
  </li>
);

LinkedListItem.propTypes = {
  item: {
    link_title: PropTypes.string,
    description: PropTypes.string,
  },
};

const LinkedList = ({
  slice: {
    items,
    primary: { list_title },
  },
}) => (
  <>
    <RichText render={list_title.raw} htmlSerializer={htmlSerializer} />
    <Ul lineHeight={'longBody'} fontSize={[2, 3]}>
      {items.map((item, idx) => (
        <LinkedListItem item={item} key={`list:${idx}`} />
      ))}
    </Ul>
  </>
);

export const LinkedListSliceType = PropTypes.shape({
  slice_type: PropTypes.oneOf(['linked_list']),
  items: PropTypes.arrayOf(LinkedListItem),
  primary: PropTypes.shape({
    list_title: PropTypes.string,
  }),
});

LinkedList.propTypes = {
  slice: LinkedListSliceType,
};

export default LinkedList;
