import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Masonry from 'react-masonry-css';
import { getImage } from 'gatsby-plugin-image';
import { RichTextType, PrismicRichText } from './shared';

import { Section, Flex, Container, Heading, Text, GatsbyImage, Box, Link } from '/components/system';

const MasonryBox = styled(Masonry)`
  display: flex;
  margin-left: 0px;
`;

const colorMap = {
  brand: ['brand', 'white'],
  red: ['negative', 'white'],
  purple: ['secondary2', 'white'],
  blue: ['blue', 'white'],
  yellow: ['yellow', 'black'],
  none: [null, 'black'],
  default: [null, 'black'],
};

const ImageGalleryItem = ({ item }) => {
  const { image, link } = item;
  const imageData = getImage(image.gatsbyImageData);
  const innerContent = (
    <Flex my={[4, null, 8]} mx={[0]} minHeight={'120px'} alignItems="center">
      <GatsbyImage image={imageData} />
    </Flex>
  );

  return link && link.url ? (
    <Link href={link.url} target={'_blank'}>
      {innerContent}
    </Link>
  ) : (
    innerContent
  );
};

export const ImageGalleryItemType = PropTypes.shape({
  image: PropTypes.shape({
    gatsbyImageData: PropTypes.object,
  }),
  link: PropTypes.shape({
    url: PropTypes.string,
  }),
});

ImageGalleryItem.propTypes = {
  item: ImageGalleryItemType,
};

const ImageGallery = ({
  slice: {
    primary: { section_title, section_body, background_color },
    items,
  },
}) => {
  const masonryCols = items.length === 1 ? { default: 1 } : { default: 3, 700: 2 };
  const [bgColor, textColor] = colorMap[background_color] ?? colorMap.default;

  return (
    <Container
      position="relative"
      display="flex"
      mb={5}
      py={[8, 8, 6, 6]}
      px={[4, 10, 12, 12]}
      flexDirection="column"
      bg={bgColor}
    >
      <Section width={1} py={6} justifyContent="flex-start" alignItems="start" flexDirection="column">
        <Flex flex={'1 1 auto'} pb={[6]} flexDirection="column" justifyContent="center" width={[1]}>
          <Box px={[4, 0, null]}>
            {section_title && (
              <PrismicRichText maxWidth={720} color={textColor} content={section_title} my={8} variant="primary" />
            )}
            {section_body && <PrismicRichText maxWidth={720} color={textColor} content={section_body} />}
          </Box>
          <MasonryBox breakpointCols={masonryCols}>
            {items.map((item, index) => (
              <ImageGalleryItem key={index} item={item} />
            ))}
          </MasonryBox>
        </Flex>
      </Section>
    </Container>
  );
};

export const ImageGallerySliceType = PropTypes.shape({
  slice_type: PropTypes.oneOf(['image_gallery']),
  primary: {
    section_title: RichTextType,
    section_body: RichTextType,
    background_color: PropTypes.oneOf(['brand', 'red', 'purple', 'blue', 'yellow', 'none']),
  },
  items: PropTypes.arrayOf(ImageGalleryItemType),
});

ImageGallery.propTypes = {
  slice: ImageGallerySliceType,
};

export default ImageGallery;
