import PropTypes from 'prop-types';
import React, { useState, useCallback, useMemo, memo } from 'react';
import { AnimatePresence, LayoutGroup } from 'framer-motion';
import { css } from '@styled-system/css';
import _ from 'lodash';

import useWindowSize from '/hooks/useWindowSize';
import { AnimatedFlex, Flex, Box, Button, Icon } from '/components/system';

const variants = {
  initial: {
    opacity: 0,
    scale: 0.2,
  },
  exit: {
    opacity: 0,
    scale: 0.2,
  },
  active: {
    opacity: 1,
    x: 0,
    scale: 1,
    transition: {
      delay: 0.2,
    },
  },
};

const Carousel = ({ children, slidesToShow = 2 }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const { width, height } = useWindowSize();

  const previousSlide = useCallback(() => {
    setSlideIndex(slideIndex > 0 ? slideIndex - 1 : 0);
  }, [setSlideIndex, slideIndex]);

  const nextSlide = useCallback(() => {
    setSlideIndex(slideIndex + 1);
  }, [setSlideIndex, slideIndex]);

  const isSingular = width && width <= 600;

  const LeftButton = (
    <Flex flexDirection="column" justifyContent="center">
      <Button flex="0 0 auto" px={2} py={2} variant="icon" onClick={previousSlide}>
        <Icon name={'arrow-l'} size="500" color="black" />
      </Button>
    </Flex>
  );

  const RightButton = (
    <Flex flexDirection="column" justifyContent="center">
      <Button flex="0 0 auto" px={2} py={2} variant="icon" onClick={nextSlide}>
        <Icon name={'arrow-r'} size="500" color="black" />
      </Button>
    </Flex>
  );

  const Slides = (
    <>
      <AnimatePresence initial={false} exitBeforeEnter={isSingular}>
        {_.range(slideIndex, slideIndex + (isSingular ? 1 : slidesToShow)).map((index) => (
          <AnimatedFlex
            flex="0 1 auto"
            key={index % children.length}
            layout
            variants={variants}
            initial={'initial'}
            animate={'active'}
            exit={'exit'}
            transition={{
              layout: {
                delay: 0.05,
              },
              default: {
                duration: 0.1,
              },
            }}
            alignItems="stretch"
          >
            {children[index % children.length]}
          </AnimatedFlex>
        ))}
      </AnimatePresence>
    </>
  );

  return isSingular ? (
    <AnimatedFlex layout flexDirection="column" gap={8}>
      <AnimatedFlex layout gridGap={12} alignItems={'stretch'} width="100%">
        {Slides}
      </AnimatedFlex>
      <AnimatedFlex layout justifyContent="center" gap={8}>
        {LeftButton}
        {RightButton}
      </AnimatedFlex>
    </AnimatedFlex>
  ) : (
    <AnimatedFlex flexDirection="column">
      <AnimatedFlex layout gridGap={12} alignItems={'stretch'} width="100%" justifyContent={'space-between'}>
        {LeftButton}
        {Slides}
        {RightButton}
      </AnimatedFlex>
    </AnimatedFlex>
  );
};

Carousel.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  slidesToShow: PropTypes.number,
};

export default memo(Carousel);
