import styled from 'styled-components';
import { composeStyleProps } from './shared';

const Image = styled('img')(composeStyleProps);
Image.defaultProps = {
  maxWidth: '100%',
  height: 'auto',
  m: 0,
};

export default Image;
