import { Link as GatsbyLink, graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import css from '@styled-system/css';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';
import { Flex, Box, Text, Container, Link, Image, Icon } from './system';
import { PrismicRichText } from '/components/prismic/shared';
import { useGrowsurfReferrer } from '/hooks/useGrowsurf';

import Head from './Head';
import Header from './Header';
import Footer from './Footer';
import { Navbar } from './Navbar';
import theme from '../styles/theme';

const Year = new Date().getFullYear();

const query = graphql`
  {
    prismicGlobalVariables {
      _previewable
      data {
        banner {
          richText
        }
      }
    }
  }
`;

const Layout = ({
  children,
  showNav,
  seoTitle,
  seoDescription,
  seoImage,
  schema,
  showFooter = true,
  showBanner = true,
  insideContainer = true,
  navbarProps = {},
}) => (
  <StaticQuery
    query={query}
    render={(staticData) => {
      const {
        data: {
          prismicGlobalVariables: {
            data: { banner },
          },
        },
        isPreview,
      } = useMergePrismicPreviewData(staticData);
      const referrer = useGrowsurfReferrer();

      return (
        <ThemeProvider theme={theme}>
          <Flex
            bg="background.default"
            flexDirection="column"
            minHeight="100vh"
            overflowX={'hidden'}
            justifyContent="space-between"
          >
            <Head
              {...(seoTitle ? { title: seoTitle } : {})}
              {...(seoDescription ? { description: seoDescription } : {})}
              {...(seoImage ? { image: seoImage } : {})}
              schema={schema}
            />
            {referrer && referrer.firstName ? (
              <Flex width={'100vw'} bg={'brand'} justifyContent="center">
                <Text variant="subtext" color="white" mx={3}>{`🎉 ${referrer.firstName} referred you to Heyday!`}</Text>
              </Flex>
            ) : (
              showBanner &&
              banner?.richText?.[0]?.text && (
                <Flex width={'100vw'} bg={'brand'} justifyContent="center">
                  <PrismicRichText variant={'subtext'} color="white" mx={3} content={banner} />
                </Flex>
              )
            )}
            {(showNav || insideContainer) && (
              <Container
                as="main"
                display="flex"
                pt={[8, 8, 6, 6]}
                pb={0}
                px={[6, 10, 12, 12]}
                flexDirection="column"
                justifyContent="start"
                transition="unset"
                {...(insideContainer && { flex: '1 1 auto' })}
              >
                {showNav && (
                  <Header>
                    <Navbar {...navbarProps} />
                  </Header>
                )}
                {insideContainer && children}
              </Container>
            )}
            {!insideContainer && children}
            {showFooter && (
              <Footer bg="pallete.neutral.darkest">
                <Flex
                  my={8}
                  justifyContent={['stretch', 'space-between', 'space-between']}
                  flexDirection={['column', 'row']}
                  alignItems={['center', 'flex-start']}
                  flexWrap="wrap"
                >
                  <Flex flexDirection="column">
                    <Link as={GatsbyLink} to="/">
                      <Icon name="logo" height={36} color="white" mx="auto" />
                    </Link>
                    <Text pt={2} variant="200" color="text.muted" textAlign={['center', 'left']}>
                      © Canopy Labs, Inc. {Year}
                    </Text>
                  </Flex>
                  <Flex flexDirection="row" gridGap={8}>
                    <Flex flexDirection="column" gridGap={2} width="120px" display={['none', 'flex']}>
                      <Text fontWeight="600" fontSize="400" mb={3} color="white">
                        Subscribe
                      </Text>
                      <Link as={GatsbyLink} to="/pricing/" color="white">
                        Pricing
                      </Link>
                    </Flex>
                    <Flex flexDirection="column" gridGap={2} width="120px">
                      <Text fontWeight="600" fontSize="400" mb={3} color="white">
                        Company
                      </Text>
                      <Link as={GatsbyLink} to="/about/" color="white" mr={3}>
                        About Us
                      </Link>
                      <Link as={GatsbyLink} to="/about/jobs" color="white" mr={3}>
                        Careers
                      </Link>
                      <Link as={GatsbyLink} to="/blog/" color="white" mr={3}>
                        Blog
                      </Link>
                      <Link as={GatsbyLink} to="/privacy/" color="white">
                        Privacy
                      </Link>
                      <Link as={GatsbyLink} to="/terms/" color="white">
                        Terms of Service
                      </Link>
                    </Flex>
                    <Flex flexDirection="column" gridGap={2} width="120px">
                      <Text fontWeight="600" fontSize="400" mb={3} color="white">
                        Tips & Support
                      </Text>
                      <Link as={GatsbyLink} to="/faq/" color="white" mr={3}>
                        FAQ
                      </Link>
                      <Link as={GatsbyLink} to="/changelog/" color="white" mr={3}>
                        Changelog
                      </Link>
                      <Link as={GatsbyLink} to="mailto:hello@heyday.xyz" color="white" mr={3}>
                        Contact Us
                      </Link>
                    </Flex>
                    <Flex flexDirection="column" display={['none', 'flex']} width="120px">
                      <Text fontWeight="600" fontSize="400" mb={3} color="white">
                        Follow Us
                      </Text>
                      <Flex
                        justifyContent={['space-evenly', 'flex-start']}
                        mt={3}
                        mb={3}
                        fontWeight="600"
                        fontSize="400"
                      >
                        <Link href="https://twitter.com/heyday_hq" color="white" title="Twitter">
                          <Box
                            borderRadius={900}
                            p={3}
                            mr={3}
                            backgroundColor="pallete.neutral.dark"
                            css={css({ ':hover': { backgroundColor: 'brand' } })}
                          >
                            <Icon name="twitter" color="white" size={16} />
                          </Box>
                        </Link>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Footer>
            )}
          </Flex>
        </ThemeProvider>
      );
    }}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  showNav: PropTypes.bool.isRequired,
  seoTitle: PropTypes.string,
  seoDescription: PropTypes.string,
  seoImage: PropTypes.string,
  showFooter: PropTypes.bool,
  schema: PropTypes.object,
  showBanner: PropTypes.bool,
  navbarProps: Navbar.propTypes,
  insideContainer: Navbar.propTypes,
};

Layout.defaultProps = {
  showNav: true,
};

export default Layout;
