import React from 'react';
import styled from 'styled-components';
import propTypes from '@styled-system/prop-types';
import { GatsbyImage as Img } from 'gatsby-plugin-image';
import { composeStyleProps } from './shared';

const GatsbyImageComponent = styled(Img)(composeStyleProps, {});

const GatsbyImage = (props) => {
  let normalizedProps = props;
  if (props.fluid && props.fluid.presentationWidth) {
    normalizedProps = {
      ...props,
      style: {
        ...(props.style || {}),
        maxWidth: props.fluid.presentationWidth,
        margin: '0 auto', // Used to center the image
      },
    };
  }

  return <GatsbyImageComponent {...normalizedProps} />;
};

GatsbyImage.propTypes = {
  fluid: propTypes.object,
  style: propTypes.object,
};

export default GatsbyImage;
