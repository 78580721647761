import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const SEO = ({ title, description, image, siteUrl, twitterHandle, twitterImage, siteLang, schema }) => {
  return (
    <Helmet title={title} titleTemplate={title.includes('Heyday') ? title : `${title}  | Heyday`}>
      {/* General tags */}
      <meta name="description" content={description} />
      <meta name="image" content={image} />
      <meta name="robots" content="max-image-preview:large" />

      {/* Schema.org tags */}
      <script type="application/ld+json">{JSON.stringify(schema)}</script>

      {/* OpenGraph tags */}
      <meta property="og:url" content={siteUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:site_name" content={'Heyday'} />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={twitterHandle} />
      <meta name="twitter:site" content={twitterHandle} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={twitterImage} />

      <html lang={siteLang} />
    </Helmet>
  );
};

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  siteUrl: PropTypes.string.isRequired,
  twitterHandle: PropTypes.string,
  twitterImage: PropTypes.string,
  siteLang: PropTypes.string.isRequired,
  schema: PropTypes.object,
};

export default SEO;
