import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NotionRenderer } from 'react-notion-x';
import styles from 'react-notion-x/src/styles.css';

import { Section, Flex, Container, Heading } from '/components/system';

const useNotionPage = (pageId) => {
  const [notionRecord, setNotionRecord] = useState(null);

  useEffect(async () => {
    const record = await fetch(`https://notion-api.splitbee.io/v1/page/${pageId}`).then((res) => res.json());
    setNotionRecord({ block: record });
  }, [pageId, setNotionRecord]);

  return notionRecord;
};

const getNotionTitle = (record) => {
  let title = null;

  Object.entries(record.block).forEach(([key, value]) => {
    if (value.value.type === 'page') {
      [[title]] = value.value.properties.title;
    }
  });

  return title;
};

const HeydayNotionRenderer = styled(NotionRenderer)`
  --notion-font: ${(props) => props.theme.fonts.sans};
  --fg-color: ${(props) => props.theme.colors.text.default};
  padding-left: 0 !important;
  padding-right: 0 !important;
  & > .notion-h1 {
    font-size: 32px;
  }
  & > .notion-h2 {
    font-size: 24px;
  }
  & > .notion-h3 {
    font-size: 20px;
  }
  & > .notion-h4 {
    font-size: 16px;
  }
  & > .notion-text {
    line-height: 1.4;
  }
  & > .notion-list li {
    padding: 0px;
    line-height: 1.4;
  }
  ${styles}
`;

const EmbeddedNotionPage = ({
  slice: {
    primary: { notion_page_id },
  },
}) => {
  const notionRecord = useNotionPage(notion_page_id);

  return (
    <Container position="relative" display="flex" mb={5} py={[8, 8, 6, 6]} px={[8, 10, 12, 12]} flexDirection="column">
      <Section width={1} py={6} justifyContent="flex-start" alignItems="start" flexDirection="column">
        <Flex
          flex={'1 1 auto'}
          maxWidth={'720px'}
          pb={[6]}
          flexDirection="column"
          justifyContent="center"
          width={[1, 1, 0.8, 0.7, null]}
          // alignItems="center"
          // textAlign="center"
        >
          {notionRecord && (
            <Heading variant="primary" as="h1">
              {getNotionTitle(notionRecord)}
            </Heading>
          )}
          {notionRecord && <HeydayNotionRenderer recordMap={notionRecord} fullPage={false} darkMode={false} />}
        </Flex>
      </Section>
    </Container>
  );
};

export const EmbeddedNotionPageSliceType = PropTypes.shape({
  slice_type: PropTypes.oneOf(['embedded_notion_page']),
  primary: {
    notion_page_id: PropTypes.string,
  },
});

EmbeddedNotionPage.propTypes = {
  slice: EmbeddedNotionPageSliceType,
};

export default EmbeddedNotionPage;
