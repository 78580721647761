import styled from 'styled-components';
import { css } from '@styled-system/css';
import { variant } from 'styled-system';

import { composeStyleProps } from './shared';

const LinkVariants = variant({
  variants: {
    primary: {
      my: 2,
      py: 3,
      color: 'brand',
      ':hover': {
        color: 'text.primary',
      },
    },
    secondary: {
      color: 'white',
      my: 2,
      py: 3,
      ':hover': {
        color: 'text.overlayLight',
      },
      ':visited': {
        color: 'white',
      },
    },
    subtext: {
      color: 'white',
      ':hover': {
        color: 'text.overlayLight',
      },
      ':visited': {
        color: 'white',
      },
      mx: 0,
      'text-underline-offset': '0.2em',
    },
    body: {
      textDecoration: 'underline #028030',
      'text-underline-offset': '0.2em',
      ':hover': {
        boxShadow: 'boxTintSmall',
        bg: 'background.brandTint',
        borderRadius: 1,
        textDecoration: 'none',
      },
    },
    naked: {
      textDecoration: 'none',
      ':hover': {
        color: 'brand',
      },
    },
  },
});

const Link = styled('a')(
  composeStyleProps,
  {
    cursor: 'pointer',
    appearance: 'none',
  },
  LinkVariants
);

Link.defaultProps = {
  color: 'inherit',
  variant: 'naked',
};

export default Link;
