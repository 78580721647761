import React, { useState } from 'react';
import { graphql, useStaticQuery, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Masonry from 'react-masonry-css';
import { getImage } from 'gatsby-plugin-image';
import { RichText } from 'prismic-reactjs';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';

import htmlSerializer, { htmlSerializerFactory } from '/utils/htmlSerializer';
import { RichTextType } from '/components/prismic/shared';

import { Box, Flex, Section, GatsbyImage, Container, Text, Icon, Heading, Card } from '/components/system';
import { Carousel } from '/components';

const bgColors = ['background.secondary1tint', 'background.secondary2tint', 'background.brandTint', 'background.peach'];

export const sectionStyles = {
  position: 'relative',
  justifyContent: ['center', null, 'stretch'],
  mb: [6, null, 8, 10],
};

const MasonryBox = styled(Masonry)`
  display: flex;
  margin-left: -16px;
`;

const TestimonialCardInner = ({ testimonial: { quote, source_name, source_title, source_image } }) => (
  <Box display="grid" gridTemplateColumns="1fr 5fr" gridColumnGap={2} my={6} mx={5}>
    <Flex flexDirection="column">
      <Box borderRadius={'50%'} isolation="isolate" overflow="hidden" width="48px" height="48px" margin="0 auto">
        <GatsbyImage
          image={getImage(source_image)}
          width={48}
          height={48}
          objectPosition="center center"
          objectFit="cover"
        />
      </Box>
    </Flex>
    <Flex flexDirection="column">
      <RichText render={quote.richText} htmlSerializer={htmlSerializerFactory({ variant: 'subtext', mt: 0 })} />
      <Text variant="subtext" mb={0}>
        {source_name}
      </Text>
      <Text variant="subtext" fontWeight="600" mt={0} mb={0}>
        {source_title}
      </Text>
    </Flex>
  </Box>
);

export const TestimonialCard = ({
  testimonial,
  rotated = false,
  rotation = '-5deg',
  handColor = 'yellow',
  ...props
}) => {
  return rotated ? (
    <Card transform={`rotate(${rotation})`} {...props}>
      <Box position="absolute" top="-16px" left="50%">
        <Icon size={32} name="hold" color={handColor} transform="rotate(180deg)" />
      </Box>
      <TestimonialCardInner testimonial={testimonial} />
    </Card>
  ) : (
    <Card display="flex" m={4} {...props}>
      <TestimonialCardInner testimonial={testimonial} />
    </Card>
  );
};

export const TestimonialType = PropTypes.shape({
  quote: RichTextType,
  source_name: PropTypes.string,
  source_title: PropTypes.string,
  source_image: PropTypes.object,
});

TestimonialCardInner.propTypes = {
  testimonial: TestimonialType,
};

TestimonialCard.propTypes = {
  testimonial: TestimonialType,
  rotated: PropTypes.bool,
  rotation: PropTypes.string,
  handColor: PropTypes.string,
};

const query = graphql`
  query {
    allPrismicTestimonials(filter: { data: { show_on_testimonials: { ne: false } } }) {
      edges {
        node {
          data {
            quote {
              richText
            }
            source_image {
              gatsbyImageData
            }
            source_name
            source_title
          }
        }
      }
    }
  }
`;

export const Testimonials = () => {
  return (
    <StaticQuery
      query={query}
      render={(staticData) => {
        const { data, isPreview } = useMergePrismicPreviewData(staticData);

        const {
          allPrismicTestimonials: { edges: testimonialEdges },
        } = data;

        return (
          <Container>
            <Section {...sectionStyles}>
              <Flex flexDirection="column" maxWidth={'100%'}>
                <Heading variant="primary">People who do a lot of research love Heyday</Heading>
                <MasonryBox breakpointCols={{ default: 3, 1100: 2, 700: 1 }}>
                  {testimonialEdges.map((edge, index) => (
                    <TestimonialCard testimonial={edge.node.data} key={`testimonial:${index}`} index={index} />
                  ))}
                </MasonryBox>
              </Flex>
            </Section>
          </Container>
        );
      }}
    />
  );
};
