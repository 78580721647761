import styled from 'styled-components';
import { motion } from 'framer-motion';
import { composeStyleProps } from './shared';

const AnimatedFlex = styled(motion.div)(composeStyleProps, {
  display: 'flex',
  transition: 'none',
});

export default AnimatedFlex;
