import styled from 'styled-components';
import { composeStyleProps } from './shared';

const Ul = styled('ul')(composeStyleProps);

Ul.defaultProps = {
  pl: 5,
};

export default Ul;
