import { graphql, useStaticQuery } from 'gatsby';
import { createGlobalStyle } from 'styled-components';
import PropTypes from 'prop-types';
import React from 'react';
import SEO from './SEO';
import useGrowsurf from '/hooks/useGrowsurf';
import '../assets/fonts/fonts.css';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${(props) => props.theme.fonts.sans};
    margin: 0px;
  }
  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  strong {
    font-weight: 600;
  }
`;

const query = graphql`
  {
    site {
      siteMetadata {
        title
        description
        language
        siteUrl
      }
    }
    prismicGlobalVariables {
      data {
        banner {
          richText
        }
        description
        title
        twitter_og_image {
          url
        }
      }
    }
  }
`;

const Head = ({ title, description, image, schema }) => {
  const data = useStaticQuery(query);
  useGrowsurf('fbhwr9');

  const schemaOrgJSONLD = schema || {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    url: data.site.siteMetadata.siteUrl,
    name: 'Heyday',
    legalName: 'Canopy Labs, Inc.',
    logo: 'https://assets.heyday.xyz/logo.png',
    foundingDate: '2021',
    founders: [
      {
        '@type': 'Person',
        name: 'Samiur Rahman',
        sameAs: ['https://linkedin.com/in/samiurr', 'https://twitter.com/samiur1204'],
      },
      {
        '@type': 'Person',
        name: 'Sam DeBrule',
        sameAs: ['https://linkedin.com/in/samdebrule', 'https://twitter.com/SamDeBrule'],
      },
    ],
    address: {
      '@type': 'PostalAddress',
      streetAddress: '604 Mission St. #500',
      addressLocality: 'San Francisco',
      addressRegion: 'CA',
      postalCode: '94105',
      addressCountry: 'USA',
    },
    contactPoint: {
      '@type': 'ContactPoint',
      contactType: 'customer support',
      email: 'hello@heyday.xyz',
    },
    sameAs: ['http://www.linkedin.com/company/heyday-hq2/', `https://twitter.com/heyday_hq`],
  };

  return (
    <>
      <SEO
        title={title || data.prismicGlobalVariables.data.title || data.site.siteMetadata.title}
        description={description || data.prismicGlobalVariables.data.description || data.site.siteMetadata.description}
        image={
          image ||
          data.prismicGlobalVariables.data.twitter_og_image.url ||
          'https://assets.heyday.xyz/social/twitter.png'
        }
        twitterImage={
          image ||
          data.prismicGlobalVariables.data.twitter_og_image.url ||
          'https://assets.heyday.xyz/social/twitter.png'
        }
        siteUrl={data.site.siteMetadata.siteUrl}
        twitterHandle={'@heyday_hq'}
        siteLang={data.site.siteMetadata.language}
        schema={schemaOrgJSONLD}
      />
      <GlobalStyle />
    </>
  );
};

Head.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  schema: PropTypes.object,
};

export default Head;
