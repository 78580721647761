import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';
import htmlSerializer, { htmlSerializerFactory } from '/utils/htmlSerializer';
import { Heading, Flex, Box, Container, Section, Icon } from '/components/system';
import { SignupButton } from '/components';
import { RichTextType } from './shared';

const SkewedSpan = styled.span`
  transform: rotate(10deg);
  transform-origin: bottom left;
  display: inline-block;
`;

const NoWrapSpan = styled.span`
  white-space: nowrap;
  position: relative;
`;

const LandingPageCTA = ({
  slice: {
    primary: {
      header,
      subheader,
      cta,
      cta_link: { url: cta_url },
      tilted,
    },
  },
}) => {
  const headerWords = header.richText[0].text.split(/\s/);
  const initialHeaderWords = headerWords.slice(0, -2);
  const previousHeaderWord = headerWords[headerWords.length - 2];
  const lastHeaderWord = headerWords[headerWords.length - 1];

  return (
    <Container bg="background.dark" position={'relative'}>
      <Section
        pt="12"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight={['300px', '480px']}
      >
        <Flex width={[1, 1, 0.8]} flexDirection="column" justifyContent="center" alignSelf="center" alignItems="center">
          <Flex alignItems="center" flexDirection="column" mb={tilted ? [5, 6, 7] : 0}>
            {tilted ? (
              <Heading
                variant="primary"
                fontSize={[5, 6, 7]}
                mb={[5, 6, 7]}
                pb={8}
                textAlign="center"
                color="text.light"
              >
                {initialHeaderWords.join(' ')}{' '}
                <NoWrapSpan>
                  {previousHeaderWord} <SkewedSpan>{lastHeaderWord}</SkewedSpan>
                  <Icon
                    name="point"
                    color="secondary1"
                    size={[30, 48, 60]}
                    position="absolute"
                    right={0}
                    top="100%"
                    mt={[4, 5, 6]}
                  />
                </NoWrapSpan>
              </Heading>
            ) : (
              <Heading variant="primary" fontSize={[5, 6, 7]} textAlign="center" color="text.light">
                {header.richText[0].text}
              </Heading>
            )}
          </Flex>
          <Box mb={6}>
            <SignupButton fontSize={['400', '500']} px={[6, 10]} my={0} text={cta} href={cta_url} />
          </Box>
          <RichText
            render={subheader.richText}
            htmlSerializer={htmlSerializerFactory({ color: 'white', textAlign: 'center' })}
          />
        </Flex>
      </Section>
    </Container>
  );
};

export const LandingPageCTASliceType = PropTypes.shape({
  slice_type: PropTypes.oneOf(['call_to_action']),
  primary: {
    header: RichTextType,
    subheader: RichTextType,
    cta: PropTypes.string,
    cta_link: PropTypes.shape({
      url: PropTypes.string,
    }),
    tilted: PropTypes.bool,
  },
});

LandingPageCTA.propTypes = {
  slice: LandingPageCTASliceType,
};

export default LandingPageCTA;
