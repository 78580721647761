import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const Highlight = styled.span`
  position: relative;
  z-index: 9;
  &:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    margin: 12px -4px 4px -${themeGet('space.2', '8px')}px;
    background-color: ${(props) => props.highlightColor || themeGet('colors.pallete.cyan.light')};
    border-radius: ${(props) => props.radii};
    transform: skewX(${(props) => props.skewX}) skewY(${(props) => props.skewY});
  }
`;

Highlight.defaultProps = {
  skewY: '0deg',
  skewX: '0deg',
  radii: '4px 16px 16px 20px',
};

export default Highlight;
