import React, { forwardRef } from 'react';

import styled from 'styled-components';
import { variant } from 'styled-system';

import { composeStyleProps } from './shared';
import Box from './Box';

const Input = forwardRef((props, ref) => (
  <Box
    ref={ref}
    as="input"
    type="text"
    tx="forms"
    variant="input"
    {...props}
    css={{
      display: 'block',
      width: '100%',
      p: 2,
      appearance: 'none',
      fontSize: 'inherit',
      lineHeight: 'inherit',
      color: 'inherit',
      bg: 'transparent',
      ':focus-visible': {
        outline: '2px auto #028030',
      },
    }}
  />
));

Input.displayName = 'Input';

export default Input;
