import styled from 'styled-components';
import { composeStyleProps } from './shared';

const Ol = styled('ol')(composeStyleProps);

Ol.defaultProps = {
  pl: 5,
};

export default Ol;
