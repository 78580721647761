import PropTypes from 'prop-types';
import React from 'react';
import ReactPlayer from 'react-player';
import Embed from './Embed';
import Image from './system/Image';

class VideoCard extends React.PureComponent {
  state = {
    videoLoaded: false,
  };

  render() {
    const { url, fallbackImage, ...props } = this.props;
    const videoStyle = { backgroundColor: 'transparent', borderRadius: '8px' };
    if (!this.state.videoLoaded) {
      videoStyle.display = 'none';
    }

    return (
      <Embed p={[2, 3, 4]} borderRadius={3} boxShadow="large">
        <ReactPlayer
          url={url}
          muted
          loop
          className="react-player"
          autoPlay
          width="100%"
          height="100%"
          style={videoStyle}
          onStart={() => this.setState({ videoLoaded: true })}
          config={{
            vimeo: {
              preload: true,
              playerOptions: {
                autoplay: true,
                background: true,
              },
            },
          }}
        />
        <Image src={fallbackImage} className="react-player" display={this.state.videoLoaded ? 'none' : null} />
      </Embed>
    );
  }
}

VideoCard.propTypes = {
  url: PropTypes.string.isRequired,
  fallbackImage: PropTypes.string,
};

export default VideoCard;
