import styled from 'styled-components';
import { variant } from 'styled-system';

import Box from './Box';

const Card = styled(Box)(variant({ key: 'variants.cards' }));

Card.defaultProps = {
  bg: 'background.light',
  boxShadow: 'large',
  borderRadius: 3,
};

export default Card;
