import styled from 'styled-components';
import { variant } from 'styled-system';
import css from '@styled-system/css';
import { composeStyleProps } from './shared';

const Blockquote = styled.blockquote(
  css({
    fontStyle: 'italic',
  }),
  variant({
    scale: 'variants.blockquote',
    variants: {
      default: {},
    },
  }),
  composeStyleProps
);
// &:before {
//   content: "\201C";
//   color:#78C0A8;
//   font-size:4em;
//   position: absolute;
//   left: 10px;
//   top:-10px;
// }
// `;

Blockquote.defaultProps = {
  variant: 'default',
};

export default Blockquote;
