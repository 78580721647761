// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';

import Hero, { HeroSliceType } from './Hero';
import GenericContent, { GenericContentSliceType } from './GenericContent';
import LinkedList, { LinkedListSliceType } from './LinkedList';
import CTA, { CTASliceType } from './CTA';
import Feature, { FeatureSliceType } from './Feature';
import Testimonials, { TestimonialsSliceType } from './Testimonials';
import LandingPageCTA, { LandingPageCTASliceType } from './LandingPageCTA';
import FAQ, { FAQSliceType } from './FAQ';
import ExistingSection, { ExistingSectionSliceType } from './ExistingSection';
import Features, { FeaturesSliceType } from './Features';
import FullWidthVideo, { FullWidthVideoSliceType } from './FullWidthVideo';
import EmbeddedNotionPage, { EmbeddedNotionPageSliceType } from './EmbeddedNotionPage';
import EmbeddedTypeform, { EmbeddedTypeformSliceType } from './EmbeddedTypeform';
import ImageGallery, { ImageGallerySliceType } from './ImageGallery';

const sliceComponents = {
  generic_content: GenericContent,
  linked_list: LinkedList,
  cta: CTA,
  hero: Hero,
  feature: Feature,
  feature_testimonials: Testimonials,
  call_to_action: LandingPageCTA,
  faq_section: FAQ,
  existing_section: ExistingSection,
  features: Features,
  full_width_video: FullWidthVideo,
  embedded_notion_page: EmbeddedNotionPage,
  embeddedtypeform: EmbeddedTypeform,
  image_gallery: ImageGallery,
};

const SliceType = PropTypes.oneOfType([
  GenericContentSliceType,
  LinkedListSliceType,
  CTASliceType,
  FeatureSliceType,
  HeroSliceType,
  TestimonialsSliceType,
  LandingPageCTASliceType,
  FAQSliceType,
  ExistingSectionSliceType,
  FeaturesSliceType,
  FullWidthVideoSliceType,
  EmbeddedNotionPageSliceType,
  EmbeddedTypeformSliceType,
  ImageGallerySliceType,
]);

const SliceZone = ({ slices }) => {
  return slices.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.slice_type];
    if (SliceComponent) {
      return <SliceComponent slice={slice} key={`slice:${index}`} index={index} />;
    }
    return null;
  });
};

SliceZone.propTypes = {
  slices: PropTypes.arrayOf(SliceType),
};

export default SliceZone;
