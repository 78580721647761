// @flow
let fontSizes = [12, 14, 16, 20, 24, 32, 48, 64, 120];
const sizes = {
  '100': '11px',
  '200': fontSizes[0],
  '300': fontSizes[1],
  '400': fontSizes[2],
  '500': fontSizes[3],
  '600': fontSizes[4],
  '700': fontSizes[5],
  '800': fontSizes[6],
  '900': fontSizes[7],
  '1000': fontSizes[8],
};

fontSizes = {
  ...fontSizes,
  ...sizes,
  hero: fontSizes[8],
  body: fontSizes[3],
};

let lineHeights = [18, 21, 24, 30, 32, 38, 48, 60, 114];
lineHeights = {
  ...lineHeights,
  '100': '14px',
  '200': '20px',
  '300': '24px',
  '400': '28px',
  '500': '32px',
  '600': '34px',
  '700': '36px',
  '800': '56px',
  '900': '64px',
  '1000': '76px',
  default: 1.25,
  header: 1,
  subheader: 1,
  line: 1,
  body: 1.25,
  longBody: 1.5,
};

export default {
  fontFamily: {
    sans: `Graphik, Inter, system-ui, Segoe UI, Roboto, Helvetica Neue, Helvetica, sans-serif`,
    serif: `Roboto Slab, serif;`,
    mono: `"SF Mono", "Monaco", "Inconsolata", "Fira Mono", "Droid Sans Mono", "Source Code Pro", monospace`,
  },
  fontSize: fontSizes,
  lineHeight: lineHeights,
  sizes,
  fontWeight: {
    fine: 200,
    thin: 300,
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    black: 900,
    default: 400,
  },
};
